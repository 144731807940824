import React, { useState, useEffect, useRef } from "react";
import JSZip from "jszip";
import html2canvas from 'html2canvas';
import styled from "styled-components";
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { NumberInput } from '../components/NumberInput'
import { Input } from '../components/Input'

import  QRCode from 'qrcode.react';

import { InputItem, Tag, HelpText } from "../styles/global";

import { requestNewQRCodes } from "../utils/api.utils";

import  { QRCodeType } from '../models'


const maskIcon = require("../assets/mask.svg");
const vaccineIcon = require("../assets/vaccine.svg");
const testIcon = require("../assets/test.svg");
const ppeIcon = require("../assets/ppe.svg");
const foodIcon = require("../assets/food.svg");
const medicationIcon = require("../assets/medication.svg");
const quizIcon = require("../assets/quiz.svg");
const sequencingIcon = require("../assets/sequence.svg");
const defaultIcon = require("../assets/default.svg");

interface Props {
  id: string;
}

interface QRProps {
  type: QRCodeType;
  code: string;
}

const renderSelectNumberRange = (max: number, min: number = 1) => {
  let a = [min], b = min;
    while (b < max) {
      a.push(b + 1 || 1);
      b += 1
    }
  return a.map((number: any) => (
    <MenuItem value={number}>{number}</MenuItem>
  ))
}

function chunkArray (arr: any[], by: number = 4) {
  let chunks = [],
  i = 0,
  n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, i += by));
  }
  return chunks;
}

const pickIconType = (type:QRCodeType) => {
  switch (type) {
    case QRCodeType.MASK:
      return maskIcon
    case QRCodeType.VACCINE:
      return vaccineIcon
    case QRCodeType.TEST:
      return testIcon
    case QRCodeType.PPE:
      return ppeIcon
    case QRCodeType.FOOD:
      return foodIcon
    case QRCodeType.MEDICATION:
      return medicationIcon
    case QRCodeType.QUIZ:
        return quizIcon
    case QRCodeType.SEQUENCING:
      return sequencingIcon   
    default:
      return defaultIcon
  }
}



export const QRCodes = ({ id }: Props) => {
  const [type, setType]  = useState<any>(QRCodeType.VACCINE);
  const [count, setCount]  = useState<any>(1);
  const [use_count, setUseCount]  = useState<any>(1);
  const [info, setInfo]  = useState<any>("");
  const [codes, setQRCodes] = useState<any[]>([]);

  const printRef = useRef<any>();

  const handleDownload = async () => {
    // let qrcodes = chunkArray(codes).map((codeGroups) => {
    //   codeGroups.map(({type, code}: QRProps) => {
    //     {type; code}
    //   })
    // })
    //const fileName = "qrcodes";

    const link = document.createElement('a');
    let zip = new JSZip();
    //let blobImg;
    let countQR = 0;
    let percentage = "0%"
    const QRCollection = document.getElementsByClassName("qr-code")!;

    let progBar = (document.getElementById("progress-bar-obj") as HTMLInputElement)!;

    // Iterate through all elements with class 'qr-code'
    let i = 0;
    (function loop() {
      let element = QRCollection.item(i) as HTMLScriptElement;
      html2canvas(element).then(function (canvas) {
        //blobImg = canvas.toDataURL("image/png");

        // Convert div to image
        canvas.toBlob(function (blob) {
          zip.file(countQR + ".png", blob!);
          countQR++;

          // Check if the amount of QR codes generated match
          if (countQR === QRCollection.length) {
            zip.generateAsync({type:"base64"}).then(function (content : string) {
              link.href="data:application/zip;base64," + content;
              link.download = type + ".zip";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            });
          }
        });
      });
      percentage = Math.floor(((i+1) / QRCollection.length) * 100) + "%";
      console.log(percentage);

      i++;
      progBar.value = "" + Math.floor(((i+1) / QRCollection.length) * 100)

      if (i < QRCollection.length) {
        setTimeout(loop, 0);
      }
    })();

    // for (let i = 0; i < QRCollection.length; i++) {
    //   let element = QRCollection.item(i) as HTMLScriptElement;
    //   html2canvas(element).then(function (canvas) {
    //     //blobImg = canvas.toDataURL("image/png");

    //     // Convert div to image
    //     canvas.toBlob(function (blob) {
    //       zip.file(countQR + ".png", blob!);
    //       countQR++;

    //       // Check if the amount of QR codes generated match
    //       if (countQR === QRCollection.length) {
    //         zip.generateAsync({type:"base64"}).then(function (content : string) {
    //           link.href="data:application/zip;base64," + content;
    //           link.download = type + ".zip";
    //           document.body.appendChild(link);
    //           link.click();
    //           document.body.removeChild(link);
    //         });
    //       }
    //     });
    //   });
    //   percentage = Math.floor(((i+1) / QRCollection.length) * 100) + "%";
    //   console.log(percentage);
    // }

  }

  // const fetchActiveQRCodes = () => {
  //   getActiveQRCodes(id)
  //     .then((result) => {
  //       const payload = result as any;
  //       setQRCodes(payload);
  //     })
  //     .catch((err) => setQRCodes([]));
  // };

  const requestQRCodes = () => {
    let progBar = (document.getElementById("progress-bar-obj") as HTMLInputElement)!;
    progBar.value = "0";
    requestNewQRCodes(id,
      {
      type,
      count,
      info,
      use_count,
    })
      .then((result) => {
        const payload = result as any;
        setQRCodes(payload);
        // handlePrint && handlePrint()
      })
      .catch((err) => setQRCodes([]));
  };


  useEffect(() => {
    // fetchActiveQRCodes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <InputItem>
        <Tag id="beacon-label">QR Code Type</Tag>
        <Select
          labelId="infection-range-label"
          required
          id="infection-range-select"
          variant="outlined"
          value={type}
          onChange={(e) => {
            const { value } = e.target;
            setType(value)
          }}
        >
          <MenuItem value={QRCodeType.VACCINE}>Vaccine</MenuItem>
          <MenuItem value={QRCodeType.MASK}>Mask</MenuItem>
          <MenuItem value={QRCodeType.PPE}>Personal Protection Equipment</MenuItem>
          <MenuItem value={QRCodeType.TEST}>Diagnostic Test</MenuItem>
          <MenuItem value={QRCodeType.FOOD}>Food Item</MenuItem>
          <MenuItem value={QRCodeType.MEDICATION}>Medication</MenuItem>
          <MenuItem value={QRCodeType.QUIZ}>Quiz</MenuItem>
          <MenuItem value={QRCodeType.SEQUENCING}>Sequencing</MenuItem>
        </Select>
      </InputItem>

      {/* <InputItem>
        <Tag id="beacon-label">Amount of codes</Tag>
        <Select
          labelId="infection-range-label"
          required
          id="infection-range-select"
          variant="outlined"
          value={count}
          onChange={(e) => {
            const { value }: { value: any} = e.target;
            const int = parseInt(value, 10);
            setCount(int || value)
          }}
        >
        {renderSelectNumberRange(16)}
      </Select>
      </InputItem> */}

      <InputItem>
        <NumberInput
          title="Code Amount"
          setWidth={100}
          required
          requiredString="Required"
          min={1}
          setValue={(value: number) => {
              if (value > 100) value = 100;
              setCount(value);
              renderSelectNumberRange(value);
            }}
          value={count}
        />
        <HelpText>(up to 100)</HelpText>
      </InputItem>
      <InputItem>
        <NumberInput
          title="Use Count"
          setWidth={100}
          min={1}
          setValue={(value: number) => {
              if (value > 100) value = 100;
              setUseCount(value);
              renderSelectNumberRange(value);
            }}
          value={use_count}
        />
        <HelpText>(up to 100)</HelpText>
      </InputItem>
      <InputItem>
        <Input
          title="Additional Info"
          placeholder="Info"
          setValue={(value: String) => {
              setInfo(value);
            }}
          value={info}
        />
      </InputItem>
      <InputItem>
        <Button
          size="large"
          variant="contained"
          disableElevation
          onClick={() => {
            // request qr codes
            requestQRCodes()
          }}
        >
          Generate Codes
        </Button>

        <DownloadButton
          size="large"
          variant="contained"
          disableElevation
          onClick={() => {
            // request qr codes
            handleDownload()
          }}
        >
          Download Codes
        </DownloadButton>


        {/* <PrintButton
          size="large"
          variant="contained"
          disableElevation
          disabled={!(codes && codes.length > 0) }
          onClick={() => {
            // request qr codes
            handlePrint && handlePrint()
          }}
        >
          Print Codes
        </PrintButton> */}

      </InputItem>

      <ProgressContainer id="progress-bar">
        Download Progress:&nbsp;
        <ProgressBar max="100" value="0" id="progress-bar-obj"></ProgressBar>
      </ProgressContainer>

      {codes && codes.length > 0 &&
        <CodeContainer id="qr-codes" ref={printRef}>
          {chunkArray(codes).map((codeGroups) => (
            <CodeRow>
              {codeGroups.map(({type, code}: QRProps, index: number) => (
                <CodeColumn className="qr-code">
                  <CodeIcon src={pickIconType(type)} alt="React Logo" />
                  <Code size={150} value={`o2://${type}?q=${code}`} level="L" />
                </CodeColumn>
              ))}
            </CodeRow>
          ))}
        </CodeContainer>
      }

      {codes && codes.length > 0 &&
        <p>GENERATED {codes.length} QR CODES - DOWNLOAD THEM IN ZIP FILE</p>
      }

    </div>
  );
};

const DownloadButton = styled(Button)`
  margin-left: 20px !important;
`

const ProgressContainer = styled.div`
  display: block;
  font-weight: bold;
`

const ProgressBar = styled.progress`
  width: 245px;
  height: 20px;
`

const Code = styled(QRCode)`
  margin: 0 auto;
`
const CodeColumn = styled.div`
  float: left;
  width: calc(25% - 48px - 1px);
  padding: 24px;
  display: flex;
  flex-direction: column;
  @media print {
    border-right: 1px dashed #e8e8e8;
    border-bottom: 1px dashed #e8e8e8;
  }
`

const CodeRow = styled.div`
  &:after{
    content: "";
    display: table;
    clear: both;
  }
  ${CodeColumn}:nth-child(4){
    @media print {
      border-right: none;
    }
  }

`

const CodeIcon = styled.img`
  padding-bottom: 24px;
  height: 60px;
  margin: 0 auto;
`
const CodeContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 900px;
  ${CodeRow}:nth-child(4n){
    break-after: always;
    page-break-after: always;
    & > ${CodeColumn}{
    @media print {
      border-bottom: none;
    }
  }
  }
`
