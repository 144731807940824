import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ViewContainer } from '../utils/ui.utils'
import { Container } from '../styles/global'
import { submitIntervention } from '../utils/api.utils'
import Button from '@material-ui/core/Button'

import Fab from '@material-ui/core/Fab';

import { Input } from '../components/Input'

import { InputItem } from '../styles/global'

interface Props {
  intervention: any
  simulation?: any | undefined
  shouldDisplay: any
  fetchInterventions: any
}

export function InterventionEditor({ intervention, simulation, shouldDisplay, fetchInterventions }: Props) {
  const [currentIntervention, setIntervention] = useState(intervention);
  // const [isValid, setIsValid] = useState<boolean>(false);

  const isValid = currentIntervention.name !== ''

  if (currentIntervention.name === undefined) currentIntervention.name = '';
  if (currentIntervention.parameters === undefined) currentIntervention.parameters = '';

  useEffect(() => {
    setIntervention((targetIntervention: any) => ({ ...targetIntervention }))
  }, []);

  function saveIntervention() {
    // send Intervention to network
    submitIntervention(currentIntervention)
      .then((response: any) => {
        fetchInterventions()
        shouldDisplay(false)
        return response
      }).catch((err: any) => console.error(err))
  }

  return (
    <>
      <ContainerWrapper>
        <Container>
          <Container>
            <Button onClick={() => shouldDisplay(false)}>{`< ${currentIntervention.name || 'Untitled Intervention'}`}</Button>
          </Container>
          <InputItem>
            <Input
              title="Intervention Name"
              required
              requiredString="Name required."
              value={currentIntervention.name}
              setValue={(value: string) => {
                setIntervention((current: any) => {
                  return { ...current, name: value };
                });
              }}
            />
          </InputItem>
          <InputItem>
            <Input
              title="Parameters"
              requiredString="Parameters required."
              value={currentIntervention.parameters}
              setValue={(value: string) => {
                setIntervention((current: any) => {
                  return { ...current, parameters: value };
                });
              }}
              as="textarea" // Use textarea for larger input
              style={{
                height: "200px",       // Makes the box taller
                width: "50%",         // Makes the box wider
                resize: "vertical",    // Allows manual resizing vertically
                padding: "10px",       // Adds some padding for readability
                textAlign: "start",    // Align text to the left
                verticalAlign: "top",  // Ensure text starts from the top
              }}
            />
          </InputItem>
          <FabWrapper
            variant="extended"
            color="primary"
            disabled={!isValid}
            onClick={() => saveIntervention()}
          >
            Save Intervention
          </FabWrapper>
        </Container>
      </ContainerWrapper>
    </>
  );
}

const ContainerWrapper = styled(ViewContainer)`
  background: #FFF;
`;

const FabWrapper = styled(Fab)`
  position: fixed !important;
  right: 40px;
  bottom: 40px;
`;
