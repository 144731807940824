import { Amplify } from 'aws-amplify'

export const env = process.env.REACT_APP_ENV === 'local' ? 'dev' : process.env.REACT_APP_ENV

export const configureAmplify = function () {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
    },
  })
}
