import React from 'react'

import InputAdornment from '@material-ui/core/InputAdornment'

import Big from 'big.js'
import { StyledNumberInput, Tag } from '../styles/global'

import { makeId } from '../utils/ui.utils'

interface Props {
  title?: string
  value: string
  setValue: (value: any) => void
  isValid?: (bool: boolean) => {}
  required?: boolean
  requiredString?: string
  // allowing spread props
  [x: string]: any
  setWidth?: number
  isDecimal?: boolean
}

const MIN = new Big(0)
const MAX = new Big(100)
const DECIMAL_MAX = new Big(1)
const ROUND_DOWN = 0

export const PercentInput = ({
  title, value, setValue, isValid, required, requiredString, setWidth = 80, isDecimal = false, ...props
}: Props) => {
  const innerValue = isDecimal ? MAX.times(new Big(value)) : new Big(value)

  const validate = (value: any): boolean => {
    const valueToValidate = new Big(value)
    const valid = valueToValidate.gte(MIN) && valueToValidate.lte(MAX)
    const validDecimal = valueToValidate.gte(MIN) && valueToValidate.lte(DECIMAL_MAX)

    if (isValid) {
      isValid(isDecimal ? validDecimal : valid)
    }
    // error prop expects the opposite
    return isDecimal ? !validDecimal : !valid
  }

  const pickValue = (value: Big): number | undefined => {
    if (value.lt(0)) {
      return MIN.toNumber()
    }

    if (isDecimal ? value.gt(DECIMAL_MAX) : value.gt(MAX)) {
      return isDecimal ? value.toNumber() : MAX.toNumber()
    }

    return value.toNumber()
  }

  const labelId = `${makeId(title || 'o2')}-label`

  return (
    <>
      {title && <Tag id={labelId}>{title}{required && ' *'}</Tag>}
      <StyledNumberInput
        setWidth={setWidth}
        id={`${makeId(title || 'o2')}-input`}
        type="number"
        aria-describedby={labelId}
        required={required}
        error={validate(value)}
        variant="outlined"
        onChange={(e: { target: { value: any } }) => {
          const number = new Big(e.target.value || 0).round(2, ROUND_DOWN)

          setValue(pickValue(isDecimal ? number.div(MAX) : number))
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        value={innerValue}
        {...props} />
    </>
  )
}
