import React from "react";

import { StyledInput, Tag } from "../styles/global";
import { makeId } from "../utils/ui.utils";


interface Props {
  title?: string;
  value: string;
  setValue: (value: any) => void;
  isValid?: (bool: boolean) => void;
  // allows a replace function to block certain characters
  replaceCharacters?: (input: string) => string;
    // allows a regex function to provided complex validation
  checkValidity?: (input: string) => boolean;
  required?: boolean;
  requiredString?: string;
  // allowing spread props
  [x:string]: any;
  setWidth?: number | string;
}

export const Input = ({ title, value, setValue, isValid, replaceCharacters, checkValidity, required, requiredString, setWidth = 190, ...props }: Props) => {
  const validate = (value: any): boolean => {
    const valid = value !== undefined && value !== null && value !== ''
    if (isValid) {
      isValid(valid);
    }
    //error prop expects the opposite
    return !valid;
  }

  const pickValue = (value: string): string | undefined => {
    if(replaceCharacters ){
      value = replaceCharacters(value)
    }
    return value
  }

  const helperText = (value: string): string | undefined => required && value === '' ? requiredString || `${title} required.` : undefined;
  const labelId = `${makeId(title || 'o2')}-label`
  return (
    <>
      {title && <Tag id={labelId}>{title}{required && ` *`}</Tag>}
      <StyledInput
        setWidth={setWidth}
        id={`${makeId(title || 'o2')}-input`}
        aria-describedby={labelId}
        required={required}
        error={required && validate(value)}
        helperText={helperText(value)}
        variant="outlined"
        onChange={(e: { target: { value: any; }; }) => {
          setValue(pickValue(e.target.value))
        }}
        value={value}
        {...props}
      />
    </>
  );
};
