import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import sortBy from "lodash/sortBy";
import Dialog from "@material-ui/core/Dialog";

import { Flex, PromptHeader, PromptBody, PromptButton, Prompt } from "../styles/global";
import { getPathogens, deletePathogen } from "../utils/api.utils";
import { PathogenEditor } from "../pages/PathogenEditor";
import { Pathogen } from "../models";
import defaultPathogen from "../data/defaultPathogen";

export const PathogenList = () => {
  const [showEditScreen, setEditScreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPathogen, setCurrentPathogen] = useState<any>();
  const [pathogens, setPathogens] = useState<Pathogen[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteIcon = require("../assets/delete.svg");

  const fetchPathogens = () => {
    getPathogens()
      .then((result: any) => {
        setLoading(false)
        const payload = result as any;
        setPathogens(sortBy(payload, "created_at"));
      })
      .catch((err) => setPathogens([]));
  };

  const createNewPathogen = () => {
    setCurrentPathogen(defaultPathogen);
    setEditScreen(true);
  };

  const selectPathogen = (pathogen: any) => {
    setCurrentPathogen(pathogen);
    setEditScreen(true);
  };

  const handlePathogenDelete = (pathogen: any) => {
    deletePathogen(pathogen.id)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    setCurrentPathogen(undefined);
    setShowDeleteModal(false);
    // delay call to fetch simulation for deletion to appear.
    setTimeout(() => fetchPathogens(), 1000);
    fetchPathogens();
  };

  const displayDeleteModal = (pathogen: any) => {
    if (!pathogen) {
      return;
    }
    setCurrentPathogen(pathogen);
    // show modal
    setShowDeleteModal(true);
  };

  useEffect(() => {
    fetchPathogens()
  }, []);

  if (showEditScreen) {
    window.scrollTo(0, 0);
    return (
      <PathogenEditor
        shouldDisplay={setEditScreen}
        fetchPathogens={fetchPathogens}
        pathogen={currentPathogen}
      />
    );
  }
  return (
    <div>
      <Flex justify="space-between" alignItems="center">
        <div>
          Pathogens
        </div>
        <Button variant="contained" color='primary' onClick={() => createNewPathogen()}>
          Add New
        </Button>
      </Flex>
      <div id="Pathogens-view">
        {!loading ?
          pathogens && pathogens.length >= 1 ?
            pathogens.map((item: any) => (
              <ListItem
                key={item.id + item.name}
                direction="row"
                justify="space-between"
              >
                <PathogenPreview
                  onClick={() => selectPathogen(item)}
                >
                  <ListTitle>{item.name && item.name}</ListTitle>
                </PathogenPreview>
                <OptionContainer alignItems="center">
                  <PromptButton
                    onClick={() => displayDeleteModal(item)}
                  >
                    <img src={deleteIcon} style={{ height: 30 }} alt="delete" />
                  </PromptButton>
                </OptionContainer>
              </ListItem>
            ))
            :
            <NotFound>No Pathogens Found</NotFound>
          :
          <NotFound>Loading...</NotFound>
        }
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={showDeleteModal}
        >
          <Prompt direction="column" justify="center" alignItems="center">
            <PromptHeader>
              Are you sure that you would like to delete this pathogen?
            </PromptHeader>
            <PromptBody>Deleting a pathogen is irreversible.</PromptBody>
            <Flex>
              <PromptButton
                onClick={() => setShowDeleteModal(false)}
                variant="contained"
              >
                Return
              </PromptButton>
              <PromptButton
                textcolor={"white"}
                tint={"#c10000"}
                onClick={() => handlePathogenDelete(currentPathogen)}
                variant="contained"
              >
                Delete
              </PromptButton>
            </Flex>
          </Prompt>
        </Dialog>
      </div>
    </div>
  );
};

const ListTitle = styled.h4`
  font-weight: 600;
  padding-bottom: 8px;
  font-size: 24px;
`;

const NotFound = styled.h4`
  font-weight: 600;
  margin-top: 120px;
  font-size: 24px;
  text-align: center;
`;

const ListItem = styled(Flex)`
  margin-top: 20px;
  padding: 16px;
  background: #F8F8F8;
  color: #000;
  border: solid 1px #666;
  border-radius: 8px;
`;

const PathogenPreview = styled(Flex)`
  flex-direction: row;
  width: 100%;
  padding: 24px 24px;
  justify-content: space-between;
  p {
    display: block;
  }
`;

const OptionContainer = styled(Flex)`
  /* padding: 20px; */

  > ${PromptButton} {
    margin: 0 16px !important;
    height: 50px;
  }
`;