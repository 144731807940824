import React, { useState } from 'react';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import { StyledInput } from "../styles/global";

interface Props {
  blackouts?: any,
  setValue: (value: any) => void;
}

export const Blackout = ({ blackouts, setValue }: Props) => {
  const [balckoutList, setBlackoutList] = useState(blackouts || []);

  return (
    <div>
      {balckoutList.map((blackout: any, index: number) => {
        return (
        <BlackoutContaier key={index}>
          <StyledInput
            setWidth={"200"}
            type="time"
            variant="outlined"
            value={balckoutList[index]?.start_time}
            onChange={(e: { target: { value: any; }; }) => {
              let newBlackOut = balckoutList;
              newBlackOut[index].start_time = e.target.value;
              setBlackoutList(newBlackOut);
              setValue(newBlackOut);
            }}
            required
          />
          <Line />
          <StyledInput
          setWidth={"200"}
            type="time"
            variant="outlined"
            value={balckoutList[index]?.end_time}
            onChange={(e: { target: { value: any; }; }) => {
              let newBlackOut = balckoutList;
              newBlackOut[index].end_time = e.target.value;
              setBlackoutList(newBlackOut);
              setValue(newBlackOut);
            }}
            required
          />
          <Line />
          <button
            onClick={() => {
              let newBlackOut = balckoutList;
              newBlackOut.splice(index, 1);
              setBlackoutList(newBlackOut);
              setValue(newBlackOut);
            }}
          >
            X
          </button>
        </BlackoutContaier>
        )
      })}
      <StyledButton
        size="large"
        variant="contained"
        disableElevation
        onClick={() => {
          let newBlackOut = balckoutList;
          newBlackOut.push({start_time: "", end_time: "", days: ["mon","tue", "wed", "thu", "fri", "sat", "sun"]});
          setBlackoutList(newBlackOut); 
          setValue(newBlackOut);
        }}
      >
        Add Blackout
      </StyledButton>
    </div>
  );
}

const StyledButton = styled(Button)`
  max-width: 280px;
  button {
    background: none;
  }
`;

const BlackoutContaier = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
`;

const Line = styled.span`
  margin-left: 12px;
  margin-right: 12px;
  background-color: #000;
  height: 3px;
  width: 24px;
`;
