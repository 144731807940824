import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { SurveyQuestionData } from '../models'
import { SurveyQuestion } from '../components/SurveyQuestion'
import { PrimaryHeader, SecondaryHeader, InputItem, Tag, Container, CloseButton } from '../styles/global'
import { submitSurvey, submissionSurveyCheck } from '../utils/api.utils'
import Button from '@material-ui/core/Button'
import { Flex } from '../styles/global'

// SurveyEditor page takes a single survey and allows it to be edited.

interface Props {
  survey: any
  shouldDisplay: any
  fetchSurveys: any
}

export function SurveyEditor({ survey, shouldDisplay, fetchSurveys }: Props) {
  const [currentSurvey, setSurvey] = useState(survey);
  const [questions, setQuestions] = useState<SurveyQuestionData[]>(currentSurvey.questions);

  useEffect(() => {
    setSurvey( (targetSurvey: any) => ({ ...targetSurvey, questions: questions }) )
  }, [questions]);

  return (
    <>
      <Container>
        <Container>
          <PrimaryHeader>Edit Survey</PrimaryHeader>
          <SecondaryHeader>Survey Details</SecondaryHeader>
        </Container>
        <Container>
          <InputItem>
            <Tag>Survey Title *</Tag>
            <textarea
                onChange={(e) => {
                    const value = e.target.value
                    setSurvey((current: any) => {
                        return { ...current, title: value }
                    })
                }}
                placeholder={currentSurvey.title}
                      />
          </InputItem>
          <InputItem>
            <Tag>Description *</Tag>
            <textarea
                onChange={(e) => {
                    const value = e.target.value
                    setSurvey((current: any) => {
                        return { ...current, description: value }
                    })
                }}
                placeholder={currentSurvey.description}
                      />
          </InputItem>
        </Container>
        <Container>
          <SecondaryHeader>Questions</SecondaryHeader>
        </Container>
        <div>
          {questions.map((questionItem: SurveyQuestionData, i: Number) => {
            return (
                <SurveyQuestion
                  key={questionItem.id.toString()}
                  question={questionItem}
                  updateQuestions={setQuestions}
                />
            );
          })}
        </div>
        <Button
          variant="contained"
          onClick={() => {
            console.log("adding new question");
            setQuestions((current: any) => [
              ...current,
              {
                id: uuidv4(),
                created_at: Date.now(),
                title: "",
                a: "",
                b: "",
                c: "",
                d: "",
                e: "",
                f: "",
              }
            ]);
          }}
        >
          + New Question
        </Button>
        <ButtonWrapper justify="space-between">
          <Button
            variant="contained"
            style={{ background: "#8bc34a", color: "#FFF" }}
            onClick={() => {
              const validatedSurvey = submissionSurveyCheck(currentSurvey)
              if (!validatedSurvey) {
                // potentially wire up to error system
                // for now, simply keep user where they are.
                return
              }
              // send survey to network
              submitSurvey(validatedSurvey)
                .then(response => {
                  fetchSurveys()
                  return response
                })
                .catch(err => console.error(err))
              // launch snackbar/toast message based on network response
              shouldDisplay(false)
            }}
          >
            Save Survey
          </Button>
          <CloseButton onClick={() => shouldDisplay(false)}>Close & Discard</CloseButton>
        </ButtonWrapper>
      </Container>
    </>
  );
}

const ButtonWrapper = styled(Flex)`
  padding-top: 120px;
  bottom: 0;
`
