import { combineReducers, Reducer } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'
import { History, LocationState } from 'history'
import { AppExampleState, appReducer } from './app/app.reducer'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppState {
  // TODO: add your fractured state types here.
  app: AppExampleState
  router: RouterState
}

export const globalReducer = (history: History<LocationState>): Reducer<AppState> => combineReducers({
  app: appReducer,
  router: connectRouter(history),
})
