import  { InfectionRange } from '../models'

export default {
    doses_per_vaccine: 20,
    fpos_info: {
        mean: 8,
        min_symptoms: 1,
        prob: 0.00005,
        sdev: 8,
        symptoms: [
            {
                prob: 100
            }
        ]
    },
    infection_info: {
        asym_period: 30,
        asym_prevalance: 0.3,
        exposed_period: 1.5,
        mild_from_asym: 0.6,
        mild_period: 32,
        mild_symptoms: [
            {
                prob: 100
            }
        ],
        min_mild_symptoms: 3,
        min_severe_symptoms: 2,
        onset_time_mean: 3,
        onset_time_sdev: 1,
        recovery_from_mild: 0.7,
        recovery_from_severe: 0.3,
        severe_from_mild: 0.4,
        severe_period: 50,
        severe_symptoms: [
            {
                prob: 72
            },
        ]
    },
    infection_prob: {
        from_asym_peer: 0.03,
        from_mild_peer: 0.03,
        from_peer: 0.01,
        from_severe_peer: 0.06,
        from_source: 0.1,
        from_reinfection: 0,
    },
    infection_range: InfectionRange.STRONG,
    name: "",
    protection_info: {
        mask_protection_factor: 0.5,
        mask_range: 0.7,
        mask_transmission_factor: 0.3,
        ppe_protection_factor: 0.1,
        ppe_range: 1,
        ppe_transmission_factor: 0
    },
    seed_parameters: {
        basic_reproduction_number: 1,
        exposed_to_inf_mild_ratio: 0,
        mean_symp_onset_to_inf_mild_ratio: 0,
        sdev_symp_onset_to_inf_mild_ratio: 0,
        inf_severe_to_inf_mild_ratio: 0,
        asym_to_inf_mild_ratio: 0,
        asym_pinf_factor: 0,
        mild_pinf_factor: 0,
        severe_pinf_factor: 0,
        prevalance_similar_disease: 0,
    },
    source_range: 2,
    strain_prevalance: 1,
    vaccine_range: 1,
}
