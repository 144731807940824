import { client } from '../api/http.clients'
import { Simulation } from '../models/index'

export const getQuizzes = (async (code: string) => {
  const result = await client().get(`/simulation/quizzes?code=${code}`)
  return result
})

export const getSurveys = (async (code: string) => {
  const result = await client().get(`/simulation/surveys?code=${code}`)
  return result
})

export const getSimulations = (async (filter?: string): Promise<Simulation[]> => {
  const url = filter ? `/simulations?filter=${filter}` : `/simulations`
  return await client().get(url) as Simulation[]
})

export const deleteSimulation = (async (id: number) => {
  const result = await client().delete(`/simulations/${id}`)
  return result
})

export const getPathogens = (async () => {
  const result = await client().get(`/pathogens`)
  return result
})

export const getPathogen = (async (id: number) => {
  const result = await client().get(`/pathogens/${id}`)
  return result
})

export const submitPathogen = (async (pathogen: any) => {
  const result = await client().post(`/pathogens`, pathogen)
  return result
})

export const deletePathogen = (async (id: number) => {
  const result = await client().delete(`/pathogens/${id}`)
  return result
})

export const submitQuiz = (async (quiz: any) => {
  const result = await client().post(`/simulation/quizzes`, quiz)
  return result
})

export const submitSurvey = (async (survey: any) => {
  const result = await client().post(`/simulation/surveys`, survey)
  return result
})

export const submitSimulation = (async (simulation: any) => {
  const result = await client().post(`/simulations`, simulation)
  return result
})

export const getInterventions = (async () => {
  const result = await client().get(`/interventions`)
  return result
})

export const getIntervention = (async (id: number) => {
  const result = await client().get(`/interventions/${id}`)
  return result
})

export const submitIntervention = (async (intervention: any) => {
  const result = await client().post(`/interventions`, intervention)
  return result
})

export const deleteIntervention = (async (id: number) => {
  const result = await client().delete(`/interventions/${id}`)
  return result
})

export const getSymptoms = (async () => {
  const result = await client().get(`/symptoms`)
  return result
})

export const getSymptom = (async (id: number) => {
  const result = await client().get(`/symptoms/${id}`)
  return result
})

export const submitSymptom = (async (symptom: any) => {
  const result = await client().post(`/symptoms`, symptom)
  return result
})

export const deleteSymptom = (async (id: number) => {
  const result = await client().delete(`/symptoms/${id}`)
  return result
})

export const pushInfection = (async (code: any) => {
  const result = await client().post(`/message`, {
    type: "case0",
    code,
  }).then((pushResponse) => console.log(pushResponse))
  return result
})

export const pushNotification = (async (code: any, title: any, payload: any, url: any, args: any) => {
  const result = await client().post(`/message`, {
    type: "open_url",
    code: code,
    title: title,
    payload: payload,
    url: url,
    args: args
  }).then((pushResponse) => console.log(pushResponse))
  return result
})

export const pushParameters = (async (code: any) => {
  const result = await client().post(`/message`, {
    type: "parameters_updated",
    code,
  }).then((pushResponse) => console.log(pushResponse))
  return result
})

export const launchQuiz = (async (code: any, quiz: any) => {
  const lockedQuiz = {
    ...quiz,
    locked: true
  }
  submitQuiz(lockedQuiz).then(res => {
    client().post(`/message`, {
      type: "quiz_available",
      payload: "A new quiz is available",
      title: "Operation Outbreak",
      code,
    }).then((pushResponse) => console.log(pushResponse))
      .catch(err => console.error("error sending push notification"))
  }).catch(err => console.error("error launching quiz"))
})

export const deleteQuiz = (async (quizID: number) => {
  const result = await client().delete(`/simulation/quizzes/${quizID}`)
  return result
})

export const launchSurvey = (async (code: any, survey: any) => {
  const lockedSurvey = {
    ...survey,
    locked: true
  }
  submitSurvey(lockedSurvey).then(res => {
    client().post(`/message`, {
      type: "quiz_available",
      payload: "A new survey is available",
      title: "Operation Outbreak",
      code,
    }).then((pushResponse) => console.log(pushResponse))
      .catch(err => console.error("error sending push notification"))
  }).catch(err => console.error("error launching survey"))
})

export const deleteSurvey = (async (surveyID: number) => {
  const result = await client().delete(`/simulation/surveys/${surveyID}`)
  return result
})

const lookup = [
  "c",
  "d",
  "e",
  "f"
]

export const submissionCheck = (targetQuiz: any) => {
  if (!targetQuiz.description) {
    console.error("quiz requires a description.")
    return
  }


  let questionErrors = targetQuiz.questions.filter((item: any) => (item.answer === ""))

  console.log(questionErrors)
  if (questionErrors.length > 0) {
    console.error("all questions require an answer.")
    return
  }

  // check for gaps in answer choices
  let answerGaps: any = []
  targetQuiz.questions.forEach((question: any) => {
    for (let i = 0; i < lookup.length; i++) {
      if (i < 1) {
        continue;
      }
      let token: any = lookup[i]
      let previous: any = lookup[i - 1]

      if (question[token] && !question[previous]) {
        console.error(`there is a gap between answer choices ${lookup[token]} and ${lookup[previous]}. please adjust.`)
        answerGaps = [...answerGaps, true]
        return
      }

    }
  })

  if (answerGaps.length) {
    return
  }


  // remove extra fields
  const copy = { ...targetQuiz }
  delete copy.id
  delete copy.created_at
  copy.questions.forEach((item: any) => {
    delete item.id
    delete item.created_at
  })

  return copy
}

export const submissionSurveyCheck = (targetSurvey: any) => {
  if (!targetSurvey.description) {
    console.error("survey requires a description.")
    return
  }

  // let questionErrors = targetSurvey.questions.filter((item: any) => (item.answer === ""))

  // console.log(questionErrors)
  // if (questionErrors.length > 0) {
  //   console.error("all questions require an answer.")
  //   return
  // }

  // check for gaps in answer choices
  let answerGaps: any = []
  targetSurvey.questions.forEach((question: any) => {
    for (let i = 0; i < lookup.length; i++) {
      if (i < 1) {
        continue;
      }
      let token: any = lookup[i]
      let previous: any = lookup[i - 1]

      if (question[token] && !question[previous]) {
        console.error(`there is a gap between answer choices ${lookup[token]} and ${lookup[previous]}. please adjust.`)
        answerGaps = [...answerGaps, true]
        return
      }

    }
  })

  if (answerGaps.length) {
    return
  }


  // remove extra fields
  const copy = { ...targetSurvey }
  delete copy.id
  delete copy.created_at
  copy.questions.forEach((item: any) => {
    delete item.id
    delete item.created_at
  })

  return copy
}

export const getActiveQRCodes = (async (id: string) => {
  const result = await client().get(`/simulations/${id}/qrcodes`)
  return result
})

export const requestNewQRCodes = (async (id: string, body: any) => {
  const result = await client().post(`/simulations/${id}/qrcodes`, body)
  return result
})

export const getSequence = (async (pathogen_id: number) => {
  const result = await client().get(`/sequence/initSequence?pathogen_id=${pathogen_id}`)
  return result
})


export const pushSequence = (async (sequence: string, pathogen_id: number) => {
  const result = await client().post(`/sequence`, {
    sequence: sequence,
    pathogen_id: pathogen_id
  }).then((pushResponse) => console.log(pushResponse))
  return result
})