import React from "react";
import styled from "styled-components";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button'

import { Tag } from '../styles/global'


import { PercentInput } from "./PercentInput";

import { Symptom, APISymptom  } from "../models"

interface Props {
  title: string;
  currentSymptoms: Symptom[];
  allSymptoms: APISymptom[]
  setValue: (value: any) => void;
}

const createSymptom = (symptomName : string, symptomLabel : string, symptomDesc : string, symptomUrl : string) => 
  ({name: symptomName,  label: symptomLabel, description: symptomDesc, url: symptomUrl, prob: 0 })
export const Symptoms = ({ title, currentSymptoms, allSymptoms, setValue }: Props) => {
  const remainingSymptoms = allSymptoms.filter(symptom =>
    currentSymptoms.every(currentSymptom => currentSymptom.name !== symptom.name));

  return (
    <SymptomsWrapper>
      <Tag>{title}</Tag>
      {currentSymptoms.map((symptom: any, index: number) => (
        <SymptomWrapper key={symptom.name + index}>
          <StyledSelect
            labelId="symptom-label"
            id="symptom-select"
            variant="outlined"
            value={symptom}
            onChange={(e) => {
              const { value }: { value: any } = e.target;
              console.log(symptom)
              let newSymptoms = currentSymptoms
              newSymptoms[index].name = value.name
              newSymptoms[index].label = value.label
              newSymptoms[index].description = value.description
              newSymptoms[index].url = value.url
              setValue(newSymptoms);
            }}
          >
            <MenuItem key={symptom.id} value={symptom}>{symptom.name}</MenuItem>
          {remainingSymptoms.map((value) => (
            <MenuItem key={value.name} value={value as any}>{value.name}</MenuItem>
          ))}
          </StyledSelect>
          <PercentInput
            required
            requiredString="Probability required."
            setValue={(value: number) => {
              if (isNaN(value)) {
                value = 0; // Set a default value if the input is not a valid number
              }
              let newSymptoms = currentSymptoms
              newSymptoms[index].prob = value
              setValue(newSymptoms);
            }}
            value={symptom.prob}
          />
          {allSymptoms.length >= 2 &&
            <button
              onClick={() => {
                let newSymptoms = currentSymptoms
                newSymptoms.splice(index,1)
                setValue(newSymptoms);
              }}
              >
            X
            </button>
          }
        </SymptomWrapper>
      ))}
      <StyledButton
        size="large"
        variant="contained"
        disabled={remainingSymptoms.length === 0}
        disableElevation
        onClick={() => {
          let newSymptoms = currentSymptoms
          let newSymptom = 
            createSymptom(remainingSymptoms[0].name, remainingSymptoms[0].label, remainingSymptoms[0].description, remainingSymptoms[0].url);
          newSymptoms.push(newSymptom)
          setValue(newSymptoms);
        }}
      >
        Add Symptom
      </StyledButton>
    </SymptomsWrapper>
  );
};


const SymptomsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledButton = styled(Button)`
  max-width: 280px;
  button {
    background: none;
  }
`

const StyledSelect = styled(Select)`
  width: 180px;
`
const SymptomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 9px 0px;
  margin: 0 -12px;
  ${StyledButton}, ${StyledSelect}, button  {
    margin: 0 12px !important;
  }
`
