import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './store'
import { SimulationList } from './pages/SimulationList'
import { PathogenList } from './pages/PathogenList'
import { SymptomList } from './pages/SymptomList'
import { InterventionList } from './pages/InterventionList'
import { Login } from './pages/Login'
import { ForgotPassword } from './pages/ForgotPassword'
import { ResetPassword } from './pages/ResetPassword'

import { DashboardWrapper } from './components/DashboardWrapper'

/**
 * The main routes of the application. Utilize this function to add more.
 */
export const routes = (
  <ConnectedRouter history={history}>
    <Switch>
      <Route
        exact
        path="/"
        component={DashboardWrapper(SimulationList)} />
      <Route
        exact
        path="/login"
        component={Login}
      />
      <Route
        exact
        path="/reset-password"
        component={ResetPassword}
      />
      <Route
        exact
        path="/forgot-password"
        component={ForgotPassword}
      />
      <Route
        exact
        path="/pathogens"
        component={DashboardWrapper(PathogenList)} />
      <Route
        exact
        path="/symptoms"
        component={DashboardWrapper(SymptomList)} />
      <Route
        exact
        path="/interventions"
        component={DashboardWrapper(InterventionList)} />
    </Switch>
  </ConnectedRouter>
)
