import jwt_decode from "jwt-decode";

export const AUTH_USER_TOKEN_KEY = 'userToken'

export const AUTH_ADMIN_USER_GROUP = 'admins'


export const checkAdmin = () => {
  const key = localStorage.getItem(AUTH_USER_TOKEN_KEY)
  if (key){
    const decoded = jwt_decode(key) as any
    return decoded['cognito:groups'] && decoded['cognito:groups'][0] === AUTH_ADMIN_USER_GROUP
  }
  return false
}

export const checkToken = () => {
  const key = localStorage.getItem(AUTH_USER_TOKEN_KEY)
  if (key){
    const decoded = jwt_decode(key) as any
    if(decoded['exp'] && Date.now() <= ( decoded['exp'] * 1000 )){
      return key
    }
  }
  //remove expired token
  localStorage.setItem(AUTH_USER_TOKEN_KEY, "")
  return false
}
