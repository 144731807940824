export enum AppActions {
  ExampleAction = '[APP] EXAMPLE_ACTION' // [FEATURE] DESCRIPTION_OF_ACTION
}

export const exampleAction = () => ({
  type: AppActions.ExampleAction,
})


export type AppActionTypes =
// utilize return type instead using action interfaces.
  | ReturnType<typeof exampleAction>
