import React, { useState } from 'react';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import { Input } from '../components/Input'

interface Props {
    groups?: any,
    setValue: (value: any) => void;
}

export const Group = ({ groups, setValue }: Props) => {
    const [groupList, setGroupList] = useState(groups || []);

    return (
        <div>
            {groupList.map((group: string, index: number) => {
                return (
                    <GroupContainer key={index}>
                        <Input
                            setValue={(value: string) => {
                                const newGroupList = [...groupList];
                                newGroupList[index] = value;
                                setGroupList(newGroupList);
                                setValue(newGroupList);
                            }}
                            value={groupList[index]}
                            required
                            requiredString="Group name required"
                        />
                        <Line />
                        <button
                            onClick={() => {
                                const newGroupList = [...groupList];
                                newGroupList.splice(index, 1);
                                setGroupList(newGroupList);
                                setValue(newGroupList);
                            }}
                        >
                            X
                        </button>
                    </GroupContainer>
                );
            })}
            <StyledButton
                size="large"
                variant="contained"
                disableElevation
                onClick={() => {
                    const newGroupList = [...groupList];
                    newGroupList.push("");
                    setGroupList(newGroupList);
                    setValue(newGroupList);
                }}
            >
                Add Group
            </StyledButton>
        </div>

    );
}

const StyledButton = styled(Button)`
  max-width: 280px;
  button {
    background: none;
  }
`;

const GroupContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
`;

const Line = styled.span`
  margin-left: 12px;
  margin-right: 12px;
  background-color: #000;
  height: 3px;
  width: 24px;
`;
