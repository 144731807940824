/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { AppContainer } from 'react-hot-loader'
import * as serviceWorker from './serviceWorker'
import { store } from './store'
import { GlobalStyle } from './styles/global'
import { App } from './app/App'

/**
 * Turns on hot reloading. This is if hot reloading is available. We turn this off for Cypress testing used by SEIT.
 */
const { hot } = module as any

if (hot) {
  hot.accept()
}

ReactDOM.render(
  <AppContainer>
    <Provider store={store}>
      <>
        <GlobalStyle />
        <App />
      </>
    </Provider>
  </AppContainer>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
