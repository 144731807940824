import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar';

import { Alert } from '../components/Alert'
import { AlertType } from '../models'
import { Input } from '../components/Input'
import { InputItem } from '../styles/global'
import { getSequence, pushSequence } from '../utils/api.utils'

interface Props {
  id: number;
}

export const SequenceForm = ({ id }: Props) => {
	const [seqStr, setSeqStr]  = useState<any>("Loading...");
	const [alertMessage, setAlertMessage] = useState<string>('');
	const [alertVisible, setAlertVisible] = useState<boolean>(false);
	const [alertSeverity, setAlertSeverity] = useState<AlertType>(AlertType.SUCCESS);

	
	const fetchSequence = () => {
		getSequence(id)
		.then((result: any) => {
		  const payload = result as any;
		  console.log(payload);
		  setSeqStr(payload.sequence);
		})
		.catch((err: any) => setSeqStr(''));
	  };

	  useEffect(() => {
		fetchSequence();
	  }, [id]);
	  
	const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
	    if (reason === 'clickaway') {
	      return;
	    }

	    setAlertVisible(false);
	    setAlertMessage("")
	    setAlertSeverity(AlertType.SUCCESS)
	 };

	return (
	 	<div>
	 		<Snackbar open={alertVisible} autoHideDuration={6000} onClose={handleAlertClose}>
		        <Alert onClose={handleAlertClose} severity={alertSeverity}>
		          {alertMessage}
		        </Alert>
	      	</Snackbar>
			<InputItem>
		        <Input
		          title="Sequence String"
		          placeholder=""
		          required
		          requiredString="Sequence string required."
		          value={seqStr}
		          setValue={(value: string) => {
		            setSeqStr(value);
		          }}
		        />
			</InputItem>
			<Button
              size="large"
              variant="contained"
              disableElevation
              onClick={() => {
				pushSequence(seqStr, id).then((response: any) => {
                    setAlertMessage("Sequence Assigned")
                    setAlertVisible(true)
                    return response
                  })
                  .catch((err: any) => console.error(err))
			  }}
            >
              Assign Sequence
            </Button>
	  	</div>
	);
};
