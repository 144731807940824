import styled, { createGlobalStyle } from "styled-components";
import { CSSReset } from "./reset";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";


// Global Styles
export const GlobalStyle = createGlobalStyle`
  ${CSSReset}
`;

export const Flex = styled.div<{
  direction?: string;
  justify?: string;
  alignItems?: string;
  wrap?: string;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ alignItems }) => alignItems};
  flex-wrap: ${({ wrap }) => wrap};
`;

export const PrimaryHeader = styled.h1`
  font-weight: 600;
  font-size: 40px;
`;

export const SecondaryHeader = styled.h2`
  font-size: 30px;
  font-weight: 300;
`;

export const Tag = styled.label`
  width: 150px;
  padding-right: 20px;
  font-weight: 600;
  line-height: 1.3;
`;

export const InputItem = styled.span`
  display: flex;
  align-items: center;
  padding: 18px 0;

  > textarea {
    resize: none;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    height: 60px;
    width: 400px;
    border: 1px solid #cfcfcf;
    margin: 8px 0;
  }

  > textarea::placeholder {
    color: #000;
  }
`;

export const Container = styled.div`
  margin: 16px 0;

  > ${PrimaryHeader} {
    padding-bottom: 20px;
  }
`;

export const CloseButton = styled(Button).attrs({
  variant: "contained",
})`
  /* position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  margin: 8px 16px !important; */
`;


export const HelpText = styled.div`
  padding-left: 12px;
  align-self: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
`;

interface StyledInputWidthProps {
  setWidth: number | string
}

export const StyledInput = styled(TextField)<StyledInputWidthProps>`
  width: ${({ setWidth }: StyledInputWidthProps) => typeof setWidth === 'number' ? `${setWidth}px`: setWidth};
`

export const StyledNumberInput = styled(StyledInput)<StyledInputWidthProps>`
  width: ${({ setWidth }: StyledInputWidthProps) => typeof setWidth === 'number' ? `${setWidth}px`: setWidth};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`


export const PromptHeader = styled.h3`
  font-size: 30px;
`;

export const PromptBody = styled.span`
  font-size: 16px;
`;

export const PromptButton = styled(Button)<{ tint?: string; textcolor?: string }>`
  background: ${({ tint }) => tint} !important;
  color: ${({ textcolor }) => textcolor} !important;
  margin: 0 8px !important;
`;

export const Prompt = styled(Flex)`
  height: 400px;
  width: 600px;
  font-family: "Roboto", sans-serif;
  text-align: center;

  ${PromptHeader} {
    margin-bottom: 20px;
  }

  ${PromptBody} {
    margin-bottom: 20px;
  }
`;
