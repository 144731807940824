import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Flex, PromptHeader, PromptBody, PromptButton,  Prompt } from "../styles/global";
import { getQuizzes, launchQuiz, deleteQuiz } from "../utils/api.utils";
import { QuizEditor } from "../pages/QuizEditor";
import { QuizDisplay } from "../pages/QuizDisplay";
import { QuizData } from "../models";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import sortBy from "lodash/sortBy";
import moment from "moment";

interface Props {
  code: string;
}

export const QuizList = ({ code }: Props) => {
  const [showEditScreen, setEditScreen] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState<QuizData>();
  const [quizzes, setQuizzes] = useState<QuizData[]>([]);
  const [showLaunchModal, setShowLaunchModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchQuizzes = useCallback(() => {
    getQuizzes(code)
      .then((result) => {
        const payload = result as any;
        setQuizzes(sortBy(payload, "created_at"));
      })
      .catch((err) => setQuizzes([]));
  }, [setQuizzes, code]);

  useEffect(() => {
    fetchQuizzes()
  }, [fetchQuizzes]);


  const createNewQuiz = () => {
    setCurrentQuiz({
      quiz_id: 0,
      title: "",
      description: "",
      locked: false,
      questions: [],
      code,
    });
    setEditScreen(true);
  };

  const displayQuizModal = (quiz: any) => {
    if (!quiz) {
      return;
    }
    setCurrentQuiz(quiz);
    // show modal
    setShowLaunchModal(true);
  };

  const displayDeleteModal = (quiz: any) => {
    if (!quiz) {
      return;
    }
    setCurrentQuiz(quiz);
    // show modal
    setShowDeleteModal(true);
  };

  const handleQuizLaunch = (code: string, quiz: any) => {
    // if good to go, send payload and refresh quizzes
    launchQuiz(code, quiz);
    setCurrentQuiz(undefined);
    setShowLaunchModal(false);
    fetchQuizzes();
  };

  const selectQuiz = (targetQuiz: any) => {
    setCurrentQuiz(targetQuiz);
    setEditScreen(true);
    // setToast((current) => ({ ...current, open: true }) )
  };

  const handleQuizDelete = (targetQuiz: any) => {
    // if (targetQuiz.locked) {
    //   console.error("quiz is locked and can not be deleted.");
    //   return;
    // }
    deleteQuiz(targetQuiz.quiz_id)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    setCurrentQuiz(undefined);
    setShowDeleteModal(false);
    // delay call to fetch quizzes for deletion to appear.
    setTimeout(() => fetchQuizzes(), 1000);
    fetchQuizzes();
  };

  const displayQuizzes = () => {
    if (quizzes) {
      return (
        <>
        {showEditScreen ?
          currentQuiz && !currentQuiz.locked ?
            <QuizEditor
              shouldDisplay={setEditScreen}
              fetchQuizzes={fetchQuizzes}
              quiz={currentQuiz}
            />
            :
            <QuizDisplay shouldDisplay={setEditScreen} quiz={currentQuiz} />
        :
          <>
          <Flex justify="space-between" alignItems="center">
            <h2 style={{ fontWeight: 600 }}>Quizzes for '{code}':</h2>
            <Button variant="contained" onClick={() => createNewQuiz()}>
              + New Quiz
            </Button>
          </Flex>
          <div id="quizzes-view">
            {quizzes &&
              quizzes.map((item: any) => (
                <ListItem
                  disabled={item.locked}
                  key={item.id + item.title}
                  direction="row"
                  justify="space-between"
                >
                  <QuizDetails direction="column">
                    <ListTitle>{item.title && item.title}</ListTitle>
                    {item.description && <p>{item.description}</p>}
                    <p>
                      Quiz ID:{" "}
                      {item.quiz_id && item.quiz_id}
                    </p>
                    <p>
                      Total # of Questions:{" "}
                      {item.questions && item.questions.length}
                    </p>
                    <p>
                      {item.created_at &&
                        `Last Updated: ${moment(item.created_at).toString()}`}
                    </p>
                    <p>Public/Launched: {item.locked ? "Yes" : "No"}</p>
                  </QuizDetails>
                  {!item.locked ? (
                    <>
                      <OptionContainer alignItems="center">
                        <PromptButton
                          variant="outlined"
                          textcolor={"#FFF"}
                          style={{ borderColor: "#FFF" }}
                          onClick={() => selectQuiz(item)}
                        >
                          Edit
                        </PromptButton>
                        <PromptButton
                          tint={"#008500"}
                          textcolor={"#FFF"}
                          onClick={() => displayQuizModal(item)}
                        >
                          Launch
                        </PromptButton>
                        <PromptButton
                          variant="outlined"
                          tint={"#c10000"}
                          textcolor={"#FFF"}
                          onClick={() => displayDeleteModal(item)}
                        >
                          Delete
                        </PromptButton>
                      </OptionContainer>
                    </>
                  ) : (
                    <OptionContainer alignItems="center">
                      <PromptButton
                        variant="outlined"
                        textcolor={"#FFF"}
                        style={{ borderColor: "#FFF" }}
                        onClick={() => selectQuiz(item)}
                      >
                        View
                      </PromptButton>
                      <PromptButton
                        variant="outlined"
                        tint={"#c10000"}
                        textcolor={"#FFF"}
                        onClick={() => displayDeleteModal(item)}
                      >
                        Delete
                      </PromptButton>
                    </OptionContainer>
                  )}
                </ListItem>
              ))}
            <Dialog
              aria-labelledby="simple-dialog-title"
              open={showLaunchModal}
            >
              <Prompt direction="column" justify="center" alignItems="center">
                <PromptHeader>Are you ready to launch this quiz?</PromptHeader>
                <PromptBody>
                  Launching a quiz is irreversible - You cannot make changes to
                  a launched quiz!
                </PromptBody>
                <Flex>
                  <PromptButton
                    onClick={() => setShowLaunchModal(false)}
                    variant="contained"
                  >
                    Return
                  </PromptButton>
                  <PromptButton
                    textcolor={"white"}
                    tint={"#008500"}
                    onClick={() => handleQuizLaunch(code, currentQuiz)}
                    variant="contained"
                  >
                    Launch
                  </PromptButton>
                </Flex>
              </Prompt>
            </Dialog>
            <Dialog
              aria-labelledby="simple-dialog-title"
              open={showDeleteModal}
            >
              <Prompt direction="column" justify="center" alignItems="center">
                <PromptHeader>
                  Are you sure that you would like to delete this quiz?
                </PromptHeader>
                <PromptBody>Deleting a quiz is irreversible.</PromptBody>
                <Flex>
                  <PromptButton
                    onClick={() => setShowDeleteModal(false)}
                    variant="contained"
                  >
                    Return
                  </PromptButton>
                  <PromptButton
                    textcolor={"white"}
                    tint={"#c10000"}
                    onClick={() => handleQuizDelete(currentQuiz)}
                    variant="contained"
                  >
                    Delete
                  </PromptButton>
                </Flex>
              </Prompt>
            </Dialog>
          </div>
          </>
          }
        </>
      );
    }
    return;
  };

  return (
    <div>
      {displayQuizzes()}
    </div>
  );
};

const ListTitle = styled.h4`
  font-weight: 600;
  padding: 12px 0;
  font-size: 24px;
`;

const ListItem = styled(Flex)<{ disabled: boolean }>`
    margin-top: 20px;
    padding: 16px;
    background: #333;
    color: #FFF;
    border-radius: 8px;
    box-shadow: 8px 8px 8px 0px rgba(33,33,33,0.6);


    ${({ disabled }) => disabled && `background: #747474;`}

    > ${Flex} > p {
        padding: 8px 0;
        font-weight: 300;
        font-size: 16px;
    }

    > button {
        cursor: pointer;
    }
`;

const OptionContainer = styled(Flex)`
  /* padding: 20px; */

  > ${PromptButton} {
    margin: 0 16px !important;
    height: 50px;
  }
`;

const QuizDetails = styled(Flex)`
  flex: 2;
`;
