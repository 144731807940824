import React, { useState, useLayoutEffect } from "react";
import styled from "styled-components";
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';
import Snackbar from '@material-ui/core/Snackbar';

import { ViewContainer, isPlural } from '../utils/ui.utils'
import { submitSimulation, pushInfection, pushParameters } from '../utils/api.utils'
import { getDuration } from '../utils/date.utils'
import { usePrevious } from '../utils/hooks.utils'
import { calculatePathogen } from '../utils/equation.utils'

import { AlertType } from '../models'

import { QuizList } from '../components/QuizList'
import { SurveyList } from '../components/SurveyList'
import { QRCodes } from '../components/QRCodes'

import { Alert } from '../components/Alert'

import { GlobalSettings } from '../components/GlobalSettings'
import { PathogenSettings } from '../components/PathogenSettings'
import { URLNotification } from '../components/URLNotification'


import { Flex, Container } from '../styles/global'


// SimulationEditor page takes a single Simulation and allows it to be edited.

interface Props {
  simulation: any
  shouldDisplay: any
  fetchSimulations: any
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

export function SimulationEditor({ simulation, shouldDisplay, fetchSimulations }: Props) {
  const [currentSimulation, setSimulation] = useState(simulation);
  const [customPathogen, setCustomPathogen] = useState(simulation.pathogen || {});
  const [tabValue, setTab] = useState<number>(0);
  // const [isValid, setIsValid] = useState<boolean>(true);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<AlertType>(AlertType.SUCCESS);

  const [alertMessage, setAlertMessage] = useState<string>('');
  const currentUnitType = currentSimulation.previousType;
  const timeStep = currentSimulation.unit_type;
  const duration = getDuration(currentSimulation.start_date, currentSimulation.end_date, 'minute')
  const prevDuration = usePrevious(duration);
  

  const isValid = currentSimulation.name !== ''
  && currentSimulation.code !== ''
  && currentSimulation.end_date
  && currentSimulation.start_date
  && duration > 0
  && currentSimulation.pathogen_id

  useLayoutEffect(() => {
    // if duration has changed, and customPathogen exists, recalculate pathogen
    if (duration !== prevDuration && Object.keys(customPathogen).length !== 0 && !simulation.is_modified){
      console.log("setCustomPathogen currentUnitType CHANGE = " + timeStep);
      setCustomPathogen(calculatePathogen(currentSimulation, customPathogen, timeStep))
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  const handleTabChange = (event: any, newValue: number) => {
    setTab(newValue);
  }

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertVisible(false);
    setAlertMessage("")
    setAlertSeverity(AlertType.SUCCESS)
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function saveSimulation() {
    let submissionSimulation = currentSimulation
    // check for modified pathogen
    if (currentSimulation.is_modified) {
      submissionSimulation = {
        ...submissionSimulation,
        pathogen: customPathogen,
      }
    }

    // send Simulation to network
    submitSimulation(submissionSimulation)
      .then((response: any) => {
        fetchSimulations()
        shouldDisplay(false)
        // check for active sim, sent push
        if (simulation.is_active) {
          pushParameters(simulation.code).then((response: any) => {
            // launch snackbar/toast message based on network response
            setAlertMessage("New Parameters Pushed")
            setAlertVisible(true)
            return response
          })
            .catch((err: any) => console.error(err))
        }
        return response
      }).catch((err: any) => console.error(err))
  }

  return (
    <>
      <Snackbar open={alertVisible} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <ContainerWrapper>
        <Container>
          <Container>
            <Button onClick={() => shouldDisplay(false)}>{`< ${currentSimulation.name || 'Untitled Simulation'}`}</Button>
          </Container>
          <ButtonWrapper>
            {simulation.archived && !simulation.is_active ?
              <Button
                size="large"
                variant="contained"
                disabled={!simulation.id || simulation.is_active}
                disableElevation
                onClick={() => {
                  // send Simulation to network
                  submitSimulation({ ...simulation, archived: false })
                    .then((response: any) => {
                      fetchSimulations()
                      shouldDisplay(false)
                      return response
                    })
                    .catch((err: any) => console.error(err))
                }}
              >
                Un-Archive
              </Button>
              :
              <Button
                size="large"
                variant="contained"
                disabled={!simulation.id || simulation.is_active}
                disableElevation
                onClick={() => {
                  // send Simulation to network
                  submitSimulation({ ...simulation, archived: true })
                    .then((response: any) => {
                      fetchSimulations()
                      shouldDisplay(false)
                      return response
                    })
                    .catch((err: any) => console.error(err))
                }}
              >
                Archive
              </Button>
            }
            <Button
              size="large"
              variant="contained"
              disabled={!simulation.code || !simulation.is_active}
              disableElevation
              onClick={() => {
                pushInfection(simulation.code)
                  .then((response: any) => {
                    // launch snackbar/toast message based on network response
                    setAlertMessage("Infection Pushed")
                    setAlertVisible(true)
                    return response
                  })
                  .catch((err: any) => console.error(err))
              }}
            >
              Push Infection
            </Button>
            {simulation.is_active && !simulation.archived ?
              simulation.active_participants ?
                simulation.infected_participants ?
                  <SimulationStatus>Simulation is active. There {isPlural(simulation.active_participants, 'is', 'are')} {simulation.active_participants} {isPlural(simulation.infected_participants, 'participant')} and {simulation.infected_participants} {isPlural(simulation.infected_participants, 'is', 'are')} infected.</SimulationStatus>
                  :
                  <SimulationStatus>Simulation is active. There {isPlural(simulation.active_participants, 'is', 'are')} {simulation.active_participants} {isPlural(simulation.infected_participants, 'participant')}.</SimulationStatus>
                :  
                <SimulationStatus>Simulation is active.</SimulationStatus>
              :
              simulation.active_participants ?
                simulation.infected_participants ?
                  <SimulationStatus>Simulation is inactive, and had {simulation.active_participants} {isPlural(simulation.active_participants, 'participant')} and {simulation.infected_participants} {isPlural(simulation.infected_participants, 'was', 'were')} infected.</SimulationStatus>
                  :
                  <SimulationStatus>Simulation is inactive, and had {simulation.active_participants} {isPlural(simulation.active_participants, 'participant')}.</SimulationStatus>
                :
                <SimulationStatus>Simulation is inactive.</SimulationStatus>
            }
          </ButtonWrapper>
          <ThemedTabs value={tabValue} onChange={handleTabChange} aria-label="simulation settings categories">
            <Tab label="Global Settings" {...a11yProps(0)} />
            <Tab label="Pathogen Settings" {...a11yProps(1)} />
            <Tab label="Quizzes" {...a11yProps(2)} />
            <Tab label="Surveys" {...a11yProps(3)} />
            <Tab label="QR Codes" {...a11yProps(4)} />
            <Tab label="URL Notification" {...a11yProps(5)} />
          </ThemedTabs>
          <ThemedTabPanel value={tabValue} index={0}>
            <GlobalSettings
              currentSimulation={currentSimulation}
              setSimulation={setSimulation}
            />
          </ThemedTabPanel>
          <ThemedTabPanel value={tabValue} index={1}>
            <PathogenSettings
              currentSimulation={currentSimulation}
              setSimulation={setSimulation}
              setCustomPathogen={setCustomPathogen}
              customPathogen={customPathogen}
              timeStep={currentSimulation.unit_type}
              currentUnitType={currentUnitType}
            />
          </ThemedTabPanel>
          <ThemedTabPanel value={tabValue} index={2}>
            <QuizList
              code={currentSimulation.code}
            />
          </ThemedTabPanel>
          <ThemedTabPanel value={tabValue} index={3}>
            <SurveyList
              code={currentSimulation.code}
            />
          </ThemedTabPanel>
          <ThemedTabPanel value={tabValue} index={4}>
            <QRCodes
              id={currentSimulation.id}
            />
          </ThemedTabPanel>
          <ThemedTabPanel value={tabValue} index={5}>
            <URLNotification
              code={currentSimulation.code}
            />
          </ThemedTabPanel>
          {tabValue !== 2 && tabValue !== 3 && tabValue !== 4 && tabValue !== 5 &&
            <FabWrapper
              variant="extended"
              color="primary"
              disabled={!isValid}
              onClick={() => saveSimulation()}
            >
              Save Simulation
            </FabWrapper>
          }
        </Container>
      </ContainerWrapper>
    </>
  );
}

const ContainerWrapper = styled(ViewContainer)`
  background: #FFF;
`

const ThemedTabPanel = styled(TabPanel)`
  margin-top: 24px;
`

const ButtonWrapper = styled(Flex)`
  padding-right: 20px;
  button {
    margin-right: 20px;
  }
`
const FabWrapper = styled(Fab)`
  position: fixed !important;
  right: 40px;
  bottom: 40px;
`
const ThemedTabs = styled(Tabs)`
  margin-top: 24px;
  margin-bottom: 24px;
`

const SimulationStatus = styled.p`
  margin-top: 12px;
  display: block;
`;
