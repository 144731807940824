import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Flex, PromptHeader, PromptBody, PromptButton,  Prompt } from "../styles/global";
import { getSurveys, launchSurvey, deleteSurvey } from "../utils/api.utils";
import { SurveyEditor } from "../pages/SurveyEditor";
import { SurveyDisplay } from "../pages/SurveyDisplay";
import { SurveyData } from "../models";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import sortBy from "lodash/sortBy";
import moment from "moment";

interface Props {
  code: string;
}

export const SurveyList = ({ code }: Props) => {
  const [showEditScreen, setEditScreen] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState<SurveyData>();
  const [surveys, setSurveys] = useState<SurveyData[]>([]);
  const [showLaunchModal, setShowLaunchModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchSurveys = useCallback(() => {
    getSurveys(code)
      .then((result) => {
        const payload = result as any;
        setSurveys(sortBy(payload, "created_at"));
      })
      .catch((err) => setSurveys([]));
  }, [setSurveys, code]);

  useEffect(() => {
    fetchSurveys()
  }, [fetchSurveys]);


  const createNewSurvey = () => {
    setCurrentSurvey({
      survey_id: 0,
      title: "",
      description: "",
      locked: false,
      questions: [],
      code,
    });
    setEditScreen(true);
  };

  const displaySurveyModal = (survey: any) => {
    if (!survey) {
      return;
    }
    setCurrentSurvey(survey);
    // show modal
    setShowLaunchModal(true);
  };

  const displayDeleteModal = (survey: any) => {
    if (!survey) {
      return;
    }
    setCurrentSurvey(survey);
    // show modal
    setShowDeleteModal(true);
  };

  const handleSurveyLaunch = (code: string, survey: any) => {
    // if good to go, send payload and refresh surveys
    launchSurvey(code, survey);
    setCurrentSurvey(undefined);
    setShowLaunchModal(false);
    fetchSurveys();
  };

  const selectSurvey = (targetSurvey: any) => {
    setCurrentSurvey(targetSurvey);
    setEditScreen(true);
    // setToast((current) => ({ ...current, open: true }) )
  };

  const handleSurveyDelete = (targetSurvey: any) => {
    // if (targetSurvey.locked) {
    //   console.error("survey is locked and can not be deleted.");
    //   return;
    // }
    deleteSurvey(targetSurvey.survey_id)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    setCurrentSurvey(undefined);
    setShowDeleteModal(false);
    // delay call to fetch surveys for deletion to appear.
    setTimeout(() => fetchSurveys(), 1000);
    fetchSurveys();
  };

  const displaySurveys = () => {
    if (surveys) {
      return (
        <>
        {showEditScreen ?
          currentSurvey && !currentSurvey.locked ?
            <SurveyEditor
              shouldDisplay={setEditScreen}
              fetchSurveys={fetchSurveys}
              survey={currentSurvey}
            />
            :
            <SurveyDisplay shouldDisplay={setEditScreen} survey={currentSurvey} />
        :
          <>
          <Flex justify="space-between" alignItems="center">
            <h2 style={{ fontWeight: 600 }}>Surveys for '{code}':</h2>
            <Button variant="contained" onClick={() => createNewSurvey()}>
              + New Survey
            </Button>
          </Flex>
          <div id="surveys-view">
            {surveys &&
              surveys.map((item: any) => (
                <ListItem
                  disabled={item.locked}
                  key={item.id + item.title}
                  direction="row"
                  justify="space-between"
                >
                  <SurveyDetails direction="column">
                    <ListTitle>{item.title && item.title}</ListTitle>
                    {item.description && <p>{item.description}</p>}
                    <p>
                      Survey ID:{" "}
                      {item.survey_id && item.survey_id}
                    </p>
                    <p>
                      Total # of Questions:{" "}
                      {item.questions && item.questions.length}
                    </p>
                    <p>
                      {item.created_at &&
                        `Last Updated: ${moment(item.created_at).toString()}`}
                    </p>
                    <p>Public/Launched: {item.locked ? "Yes" : "No"}</p>
                  </SurveyDetails>
                  {!item.locked ? (
                    <>
                      <OptionContainer alignItems="center">
                        <PromptButton
                          variant="outlined"
                          textcolor={"#FFF"}
                          style={{ borderColor: "#FFF" }}
                          onClick={() => selectSurvey(item)}
                        >
                          Edit
                        </PromptButton>
                        <PromptButton
                          tint={"#008500"}
                          textcolor={"#FFF"}
                          onClick={() => displaySurveyModal(item)}
                        >
                          Launch
                        </PromptButton>
                        <PromptButton
                          variant="outlined"
                          tint={"#c10000"}
                          textcolor={"#FFF"}
                          onClick={() => displayDeleteModal(item)}
                        >
                          Delete
                        </PromptButton>
                      </OptionContainer>
                    </>
                  ) : (
                    <OptionContainer alignItems="center">
                      <PromptButton
                        variant="outlined"
                        textcolor={"#FFF"}
                        style={{ borderColor: "#FFF" }}
                        onClick={() => selectSurvey(item)}
                      >
                        View
                      </PromptButton>
                      <PromptButton
                        variant="outlined"
                        tint={"#c10000"}
                        textcolor={"#FFF"}
                        onClick={() => displayDeleteModal(item)}
                      >
                        Delete
                      </PromptButton>
                    </OptionContainer>
                  )}
                </ListItem>
              ))}
            <Dialog
              aria-labelledby="simple-dialog-title"
              open={showLaunchModal}
            >
              <Prompt direction="column" justify="center" alignItems="center">
                <PromptHeader>Are you ready to launch this survey?</PromptHeader>
                <PromptBody>
                  Launching a survey is irreversible - You cannot make changes to
                  a launched survey!
                </PromptBody>
                <Flex>
                  <PromptButton
                    onClick={() => setShowLaunchModal(false)}
                    variant="contained"
                  >
                    Return
                  </PromptButton>
                  <PromptButton
                    textcolor={"white"}
                    tint={"#008500"}
                    onClick={() => handleSurveyLaunch(code, currentSurvey)}
                    variant="contained"
                  >
                    Launch
                  </PromptButton>
                </Flex>
              </Prompt>
            </Dialog>
            <Dialog
              aria-labelledby="simple-dialog-title"
              open={showDeleteModal}
            >
              <Prompt direction="column" justify="center" alignItems="center">
                <PromptHeader>
                  Are you sure that you would like to delete this survey?
                </PromptHeader>
                <PromptBody>Deleting a survey is irreversible.</PromptBody>
                <Flex>
                  <PromptButton
                    onClick={() => setShowDeleteModal(false)}
                    variant="contained"
                  >
                    Return
                  </PromptButton>
                  <PromptButton
                    textcolor={"white"}
                    tint={"#c10000"}
                    onClick={() => handleSurveyDelete(currentSurvey)}
                    variant="contained"
                  >
                    Delete
                  </PromptButton>
                </Flex>
              </Prompt>
            </Dialog>
          </div>
          </>
          }
        </>
      );
    }
    return;
  };

  return (
    <div>
      {displaySurveys()}
    </div>
  );
};

const ListTitle = styled.h4`
  font-weight: 600;
  padding: 12px 0;
  font-size: 24px;
`;

const ListItem = styled(Flex)<{ disabled: boolean }>`
    margin-top: 20px;
    padding: 16px;
    background: #333;
    color: #FFF;
    border-radius: 8px;
    box-shadow: 8px 8px 8px 0px rgba(33,33,33,0.6);


    ${({ disabled }) => disabled && `background: #747474;`}

    > ${Flex} > p {
        padding: 8px 0;
        font-weight: 300;
        font-size: 16px;
    }

    > button {
        cursor: pointer;
    }
`;

const OptionContainer = styled(Flex)`
  /* padding: 20px; */

  > ${PromptButton} {
    margin: 0 16px !important;
    height: 50px;
  }
`;

const SurveyDetails = styled(Flex)`
  flex: 2;
`;
