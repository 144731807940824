import { Simulation, Pathogen } from '../models'
import { getDuration, unitTimeConverter } from '../utils/date.utils'

// all equation helpers

export const calculateFromPeer = (simulation: Simulation, pathogen: Pathogen, mild_period: number, conv_constant: number) => {
  let p = pathogen.seed_parameters.basic_reproduction_number / ((simulation.number_of_contacts / simulation.number_of_contacts_in * (pathogen.infection_info.mild_from_asym * (1 - pathogen.infection_info.severe_from_mild) + pathogen.infection_info.mild_from_asym * pathogen.infection_info.severe_from_mild * (1 + pathogen.seed_parameters.inf_severe_to_inf_mild_ratio) + (1 - pathogen.infection_info.mild_from_asym) * pathogen.seed_parameters.inf_severe_to_inf_mild_ratio) * mild_period * conv_constant))
  console.log('Calculate from peer:')
  console.log('basic_reproduction_number ', pathogen.seed_parameters.basic_reproduction_number)
  console.log('number_of_contacts ', simulation.number_of_contacts)
  console.log('number_of_contacts_in ', simulation.number_of_contacts_in)
  console.log('mild_from_asym ', pathogen.infection_info.mild_from_asym)
  console.log('severe_from_mild ', pathogen.infection_info.severe_from_mild)
  console.log('inf_severe_to_inf_mild_ratio ', pathogen.seed_parameters.inf_severe_to_inf_mild_ratio)
  console.log('mild_period ', mild_period)
  console.log('conv_constant ', conv_constant)
  console.log('from peer prob ', p)
  return p
}

export const calculateFromAsymPeer = (pathogen: Pathogen, from_peer: number) => {
  return pathogen.seed_parameters.asym_pinf_factor * from_peer
}

export const calculateFromMildPeer = (pathogen: Pathogen, from_peer: number) => {
  return pathogen.seed_parameters.mild_pinf_factor * from_peer
}

export const calculateFromSeverePeer = (pathogen: Pathogen, from_peer: number) => {
  return pathogen.seed_parameters.severe_pinf_factor * from_peer
}

export const calculateMildPeriod = (simulation: Simulation, pathogen: Pathogen, duration: number) => {
  return duration / ((pathogen.seed_parameters.exposed_to_inf_mild_ratio + pathogen.infection_info.mild_from_asym * (1 - pathogen.infection_info.severe_from_mild) + pathogen.infection_info.mild_from_asym * pathogen.infection_info.severe_from_mild * (1 + pathogen.seed_parameters.inf_severe_to_inf_mild_ratio) + (1 - pathogen.infection_info.mild_from_asym) * pathogen.seed_parameters.inf_severe_to_inf_mild_ratio) * simulation.epi_generations)
}

export const calculateExposedPeriod = (pathogen: Pathogen, mild_period: number) => {
  return pathogen.seed_parameters.exposed_to_inf_mild_ratio * mild_period
}

export const calculateSeverePeriod = (pathogen: Pathogen, mild_period: number) => {
  return pathogen.seed_parameters.inf_severe_to_inf_mild_ratio * mild_period
}

export const calculateOnsetTimeMean = (pathogen: Pathogen, mild_period: number) => {
  return pathogen.seed_parameters.mean_symp_onset_to_inf_mild_ratio * mild_period
}

export const calculateOnsetTimeSdev = (pathogen: Pathogen, mild_period: number) => {
  return pathogen.seed_parameters.sdev_symp_onset_to_inf_mild_ratio * mild_period
}

export const calculateAsymPeriod = (pathogen: Pathogen, mild_period: number) => {
  return pathogen.seed_parameters.asym_to_inf_mild_ratio * mild_period
}

export const calculateFposProbability = (simulation: Simulation, pathogen: Pathogen, duration: number) => {
  return pathogen.seed_parameters.prevalance_similar_disease / (duration * 60 / simulation.time_step)
}

export const calculatePathogen = (simulation: Simulation, pathogen: Pathogen, currentUnitTime: any) => {  
  const duration = getDuration(simulation.start_date, simulation.end_date, currentUnitTime)
  const mild_period = calculateMildPeriod(simulation, pathogen, duration);
  const from_peer = calculateFromPeer(simulation, pathogen, mild_period, unitTimeConverter(currentUnitTime, 1, 'minute'));
  return {
    ...pathogen,
    infection_prob: {
      ...pathogen.infection_prob,
      from_peer,
      from_asym_peer: calculateFromAsymPeer(pathogen, from_peer),
      from_mild_peer: calculateFromMildPeer(pathogen, from_peer),
      from_severe_peer: calculateFromSeverePeer(pathogen, from_peer),
    },
    infection_info: {
      ...pathogen.infection_info,
      mild_period,
      exposed_period: calculateExposedPeriod(pathogen, mild_period),
      severe_period: calculateSeverePeriod(pathogen, mild_period),
      onset_time_mean: calculateOnsetTimeMean(pathogen, mild_period),
      onset_time_sdev: calculateOnsetTimeSdev(pathogen, mild_period),
      asym_period: calculateAsymPeriod(pathogen, mild_period),
    },
    fpos_info: {
      ...pathogen.fpos_info,
      prob: calculateFposProbability(simulation, pathogen, duration),
    }
  }
}
