import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Flex, SecondaryHeader } from '../styles/global'
import { Paper, Button } from '@material-ui/core'
import { Input } from '../components/Input'

import { Auth } from 'aws-amplify'

interface UserCredentials {
  verification_code: string;
  password1: string;
  password2: string;
}

export const ResetPassword = () => {
  const [formData, setFormData] = useState<UserCredentials>({ verification_code : '', password1: '', password2: '' })
  const [formTouched, setFormTouched] = useState<{verification_code: boolean, password1: boolean, password2: boolean}>({ verification_code: false, password1: false, password2: false })

  const [isLoading, setLoading] = useState(false)
  const history = useHistory()

  const isValid = formData && formData.password1 !== ''  && formData.password2 !== '' && formData.password1 === formData.password2

  const sendCredentials = () => {
    setLoading(true)

    let { password1, verification_code} = formData;
    let username = window.location.search.split('=')[1];

    Auth.forgotPasswordSubmit(username.trim(), verification_code.trim(), password1.trim())
      .then(async (user) => {
        history.push('/login');

        setLoading(false);
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
        alert(err.message)
      })
  }

  return (
    <Container>
      <LoginBox elevation={1}>
        <Flex direction={'column'}>
          <SecondaryHeader>Welcome to Operation Outbreak</SecondaryHeader>
          <StyledInput
            label="Verification Code"
            setWidth="100%"
            required={formTouched.verification_code}
            requiredString="Verification Code required."
            setValue={(value: string) => {
              setFormTouched({...formTouched, verification_code: true})
              setFormData(() => {
                return { ...formData, verification_code: value };
              });
            }}
            value={formData.verification_code}
          />
          <StyledInput
            label="New Password"
            setWidth="100%"
            type="password"
            required={formTouched.password1}
            requiredString="Password required."
            setValue={(value: string) => {
              setFormTouched({...formTouched, password1: true})
              setFormData(() => {
                return { ...formData, password1: value };
              });
            }}
            value={formData.password1}
          />
           <StyledInput
            label="Confirm New Password"
            type="password"
            required={formTouched.password2}
            requiredString="Password required."
            setValue={(value: string) => {
              setFormTouched({...formTouched, password2: true})
              setFormData(() => {
                return { ...formData, password2: value };
              });
            }}
            value={formData.password2}
          />
          <Button disabled={!isValid || isLoading} onClick={() => sendCredentials()} variant="contained" color="primary">
           Reset Password
          </Button>
        </Flex>
      </LoginBox>
    </Container>
  )
}

const StyledInput = styled(Input)`
  margin-bottom: 24px !important;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
  background: #333;
`

const LoginBox = styled(Paper)`
  padding: 36px;
  display: flex;
  justify-content: center;
  ${Flex} {
    ${StyledInput} {
      margin-bottom: 16px;
    }
    ${SecondaryHeader} {
      margin-bottom: 30px;
      text-align: center;
    }
  }
`
