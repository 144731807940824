import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, Link } from 'react-router-dom'
import { Flex, SecondaryHeader } from '../styles/global'
import { Paper, Button } from '@material-ui/core'
import { Input } from '../components/Input'

import { Auth } from 'aws-amplify'

interface UserCredentials {
  username: string;
  password: string;
}

export const ForgotPassword = () => {
  const [formData, setFormData] = useState<UserCredentials>({ username: '', password: '' })
  const [formTouched, setFormTouched] = useState<{username: boolean, password: boolean}>({ username: false, password: false })

  const [isLoading, setLoading] = useState(false)
  const history = useHistory()

  const isValid = formData  && formData.username !== ''

  const sendCredentials = () => {
    setLoading(true)
    Auth.forgotPassword(formData.username)
      .then(async (user) => {
        history.push(`/reset-password?username=${formData.username}`);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
        alert(err.message)
      })
  }

  return (
    <Container>
      <LoginBox elevation={1}>
        <Flex direction={'column'}>
          <SecondaryHeader>Welcome to Operation Outbreak</SecondaryHeader>
          <StyledInput
            label="E-Mail"
            setWidth="100%"
            type="email"
            required={formTouched.username}
            requiredString="E-Mail required."
            setValue={(value: string) => {
              setFormTouched({...formTouched, username: true})
              setFormData(() => {
                return { ...formData, username: value };
              });
            }}
            value={formData.username}
          />
          <Button disabled={!isValid || isLoading} onClick={() => sendCredentials()} variant="contained" color="primary">
            Reset Password
          </Button>
          <StyledLink to='/login'>
            Back to Login
          </StyledLink>
        </Flex>
      </LoginBox>
    </Container>
  )
}

const StyledInput = styled(Input)`
  margin-bottom: 24px !important;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  min-height: 100vh;

  background: #333;
`

const LoginBox = styled(Paper)`
    padding: 36px;

    display: flex;
    justify-content: center;

    ${Flex} {
      ${StyledInput} {
          margin-bottom: 16px;
      }

      ${SecondaryHeader} {
          margin-bottom: 30px;
          text-align: center;
      }
    }
`

const StyledLink = styled(Link)`
  text-align: center;
  margin-top: 24px !important;
  text-decoration: none;
  color: #000;
`
