import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import sortBy from "lodash/sortBy";
import Dialog from "@material-ui/core/Dialog";

import { Flex, PromptHeader, PromptBody, PromptButton, Prompt } from "../styles/global";
import { getSymptoms, deleteSymptom } from "../utils/api.utils";
import { SymptomEditor } from "../pages/SymptomEditor";
import { Symptom } from "../models";
import defaultSymptom from "../data/defaultSymptom";

export const SymptomList = () => {
  const [showEditScreen, setEditScreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentSymptom, setCurrentSymptom] = useState<any>();
  const [symptoms, setSymptoms] = useState<Symptom[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteIcon = require("../assets/delete.svg");

  const fetchSymptoms = () => {
    getSymptoms()
      .then((result: any) => {
        setLoading(false)
        const payload = result as any;
        setSymptoms(sortBy(payload, "created_at"));
      })
      .catch((err) => setSymptoms([]));
  };

  const createNewSymptom = () => {
    setCurrentSymptom(defaultSymptom);
    setEditScreen(true);
  };

  const selectSymptom = (symptom: any) => {
    setCurrentSymptom(symptom);
    setEditScreen(true);
  };

  const handleSymptomDelete = (symptom: any) => {
    deleteSymptom(symptom.id)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    setCurrentSymptom(undefined);
    setShowDeleteModal(false);
    // delay call to fetch simulation for deletion to appear.
    setTimeout(() => fetchSymptoms(), 1000);
    fetchSymptoms();
  };

  const displayDeleteModal = (symptom: any) => {
    if (!symptom) {
      return;
    }
    setCurrentSymptom(symptom);
    // show modal
    setShowDeleteModal(true);
  };

  useEffect(() => {
    fetchSymptoms()
  }, []);

  if (showEditScreen) {
    window.scrollTo(0, 0);
    return (
      <SymptomEditor
        shouldDisplay={setEditScreen}
        fetchSymptoms={fetchSymptoms}
        symptom={currentSymptom}
      />
    );
  }
  return (
    <div>
      <Flex justify="space-between" alignItems="center">
        <div>
          Symptoms
        </div>
        <Button variant="contained" color='primary' onClick={() => createNewSymptom()}>
          Add New
        </Button>
      </Flex>
      <div id="symptoms-view">
        {!loading ?
          symptoms && symptoms.length >= 1 ?
            symptoms.map((item: any) => (
              <ListItem
                key={item.id + item.name}
                direction="row"
                justify="space-between"
              >
                <PathogenPreview
                  onClick={() => selectSymptom(item)}
                >
                  <ListTitle>{item.name && item.name}</ListTitle>
                </PathogenPreview>
                <OptionContainer alignItems="center">
                  <PromptButton
                    onClick={() => displayDeleteModal(item)}
                  >
                    <img src={deleteIcon} style={{ height: 30 }} alt="delete" />
                  </PromptButton>
                </OptionContainer>
              </ListItem>
            ))
            :
            <NotFound>No Symptoms Found</NotFound>
          :
          <NotFound>Loading...</NotFound>
        }
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={showDeleteModal}
        >
          <Prompt direction="column" justify="center" alignItems="center">
            <PromptHeader>
              Are you sure that you would like to delete this symptom?
            </PromptHeader>
            <PromptBody>Deleting a symptom is irreversible.</PromptBody>
            <Flex>
              <PromptButton
                onClick={() => setShowDeleteModal(false)}
                variant="contained"
              >
                Return
              </PromptButton>
              <PromptButton
                textcolor={"white"}
                tint={"#c10000"}
                onClick={() => handleSymptomDelete(currentSymptom)}
                variant="contained"
              >
                Delete
              </PromptButton>
            </Flex>
          </Prompt>
        </Dialog>
      </div>
    </div>
  );
};

const ListTitle = styled.h4`
  font-weight: 600;
  padding-bottom: 8px;
  font-size: 24px;
`;

const NotFound = styled.h4`
  font-weight: 600;
  margin-top: 120px;
  font-size: 24px;
  text-align: center;
`;

const ListItem = styled(Flex)`
  margin-top: 20px;
  padding: 16px;
  background: #F8F8F8;
  color: #000;
  border: solid 1px #666;
  border-radius: 8px;
`;

const PathogenPreview = styled(Flex)`
  flex-direction: row;
  width: 100%;
  padding: 24px 24px;
  justify-content: space-between;
  p {
    display: block;
  }
`;

const OptionContainer = styled(Flex)`
  /* padding: 20px; */

  > ${PromptButton} {
    margin: 0 16px !important;
    height: 50px;
  }
`;