import { checkToken } from '../utils/auth.utils'
import { useHistory } from 'react-router-dom'

interface Props {
  children?: any
}

export const AuthCheck = ({ children }: Props) => {
  const history = useHistory()
  if (!checkToken()) {
    history.push({ pathname: '/login' });
  }
  return null
}
