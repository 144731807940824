import React from "react"
import styled from "styled-components"
import { SurveyQuestionData } from '../models'
import { SecondaryHeader, Flex, InputItem, Tag, Container, CloseButton } from '../styles/global'
import { ViewContainer } from '../utils/ui.utils'

// SurveyDisplay page takes a single survey and allows its contents to be displayed.

interface Props {
  survey: any
  shouldDisplay: any
}

export function SurveyDisplay({ survey, shouldDisplay }: Props) {
  return (
    <ContainerWrapper>
      <Container>
        <Container>
          <SecondaryHeader>Survey Details</SecondaryHeader>
        </Container>
        <Container>
          <DisplayItem>
            <Tag>Survey Title</Tag>
            <p>{survey.title}</p>
          </DisplayItem>
          <DisplayItem>
            <Tag>Description</Tag>
            <p>{survey.description}</p>
          </DisplayItem>
        </Container>
        <Container>
          <SecondaryHeader>Questions</SecondaryHeader>
        </Container>
        <div>
          {survey.questions.map((questionItem: SurveyQuestionData) => {
            return (
              <>
                <hr />
                <DisplayContainer direction={'column'}>
                  <DisplayItem>
                    <DisplayTag>Title</DisplayTag>
                    <p>{questionItem.title}</p>
                  </DisplayItem>
                  <DisplayItem>
                    <DisplayTag>A</DisplayTag>
                    <p>{questionItem.a}</p>
                  </DisplayItem>
                  <DisplayItem>
                    <DisplayTag>B</DisplayTag>
                    <p>{questionItem.b}</p>
                  </DisplayItem>
                  {questionItem.c && <DisplayItem>
                    <DisplayTag>C</DisplayTag>
                    <p>{questionItem.c}</p>
                  </DisplayItem>}
                  {questionItem.d && <DisplayItem>
                    <DisplayTag>D</DisplayTag>
                    <p>{questionItem.d}</p>
                  </DisplayItem>}
                  {questionItem.e && <DisplayItem>
                    <DisplayTag>E</DisplayTag>
                    <p>{questionItem.e}</p>
                  </DisplayItem>}
                  {questionItem.f && <DisplayItem>
                    <DisplayTag>F</DisplayTag>
                    <p>{questionItem.f}</p>
                  </DisplayItem>}
                  {questionItem.info_link && <DisplayItem>
                    <DisplayTag>More Info</DisplayTag>
                    <p>{questionItem.info_link}</p>
                  </DisplayItem>}
                </DisplayContainer>
              </>
            );
          })}
        </div>
      </Container>
      <CloseButton onClick={() => shouldDisplay(false)}>Close</CloseButton>
    </ContainerWrapper>
  );
}

const ContainerWrapper = styled(ViewContainer)`
  background: #FFF;
`

const DisplayItem = styled(InputItem)`
  padding: 16px 0;
`

const DisplayContainer = styled(Flex)`
  padding: 16px 0;
`

const DisplayTag = styled(Tag)`
  font-weight: 600;
`