import moment from 'moment'
import 'moment-timezone';

export const detectTimezone = () => {
  var tz = moment.tz.guess();
  return moment.tz(tz).format('ZZ')
}

export const serverToLocalDate = (date: any): string => {
  return moment(date, "YYYY-MM-DD HH:mm:ss").format(moment.HTML5_FMT.DATETIME_LOCAL)
}

export const localToServerDate = (date: any): string => {
  return moment(date, moment.HTML5_FMT.DATETIME_LOCAL).format("YYYY-MM-DD HH:mm:ss")
}

export const getDuration = (first_date: any, second_date: any, time_unit: any): number => {
  const dmin = moment(second_date, "YYYY-MM-DD HH:mm:ss").diff(moment(first_date,  "YYYY-MM-DD HH:mm:ss"), 'minutes')
  const dunit = unitTimeConverter('minute', dmin, time_unit);
  return dunit;
}

export const generateDate = (dayOffset?: number) => dayOffset ? moment().add(dayOffset,'days').format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss")

export const getTimeZonesList = () => {
  const zones = new Set();
  const countries = moment.tz.countries();

  for (const country of countries) {
    moment.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones)
  }

  return [...zones].sort();
}; 

export const gmtFormat = (value: any) => {
  const date = moment().tz(value).format();
  const gmt = date.slice(19).replace(":", "");

  return gmt;
};

export const unitTimeConverter = (currentUnitTime: any, unitAmount: any, unitTypeToConvert: any) => {
  const hoursInDay = 24;
  const minutesInHour = 60; // also seconds in a minute.
  const minutesInDay = 1440;
  const secondsInHour = 3600;
  const secondsInDay = 86400;

  if (currentUnitTime  === "day") {
    if (unitTypeToConvert === "day") {
      return unitAmount;
    }
    if (unitTypeToConvert === "hour") {
      return unitAmount * hoursInDay;
    }
    if (unitTypeToConvert === "minute") {
      return unitAmount * minutesInDay;
    }
    if (unitTypeToConvert === "second") {
      return unitAmount * secondsInDay;
    }
  } else if (currentUnitTime  === "hour") {
    if (unitTypeToConvert === "day") {
      return unitAmount / hoursInDay;
    }
    if (unitTypeToConvert === "hour") {
      return unitAmount;
    }
    if (unitTypeToConvert === "minute") {
      return unitAmount * minutesInHour;
    }
    if (unitTypeToConvert === "second") {
      return unitAmount * secondsInHour;
    }
  } else if (currentUnitTime  === "minute") {
    if (unitTypeToConvert === "day") {
      return unitAmount / minutesInDay;
    }
    if (unitTypeToConvert === "hour") {
      return unitAmount / minutesInHour;
    }
    if (unitTypeToConvert === "minute") {
      return unitAmount;
    }
    if (unitTypeToConvert === "second") {
      return unitAmount * minutesInHour;
    }
  } else if (currentUnitTime  === "second") {
    if (unitTypeToConvert === "day") {
      return unitAmount / secondsInDay;
    }
    if (unitTypeToConvert === "hour") {
      return unitAmount / secondsInHour;
    }
    if (unitTypeToConvert === "minute") {
      return unitAmount / minutesInHour;
    }
    if (unitTypeToConvert === "second") {
      return unitAmount;
    }
  }
  return;
};
