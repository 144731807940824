import axios from 'axios'
import { checkToken } from '../utils/auth.utils'
import { HttpClient } from './http.client'

export const env = process.env.REACT_APP_ENV
const apiUrl = process.env.REACT_APP_API_ENDPOINT

export const client = () => new HttpClient(axios.create({
  // if local, we use localhost
  baseURL: apiUrl,
  headers: {
    'x-api-key': process.env.REACT_APP_API_KEY!,
    authorization: checkToken(),
  },
}))
