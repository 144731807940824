import React, { useState, SetStateAction } from 'react'
import styled from 'styled-components'
import { removeQuestion, adjustRequestPrefix } from '../utils/ui.utils'
import moment from 'moment'
import orderBy from 'lodash/orderBy';
import { InputItem, Tag, Container } from '../styles/global'
import Button from '@material-ui/core/Button'


interface QuestionProps {
    question: any // update to question type
    updateQuestions: SetStateAction<any> // update to question type
}

// Question
export const SurveyQuestion = (props: QuestionProps) => {
    const { question, updateQuestions } = props

    const [currentQuestion, setQuestion] = useState({...question})

    const syncQuestions = (targetObj: any) => {
        // set local question data
        updateQuestions((current: any) => {
            const arr = [...current.filter((item: any) => item.id !== targetObj.id), targetObj]

            return orderBy(arr, (o: any) => {
                return moment(o.created_at)
              }, ['asc'])
        })
    }

    return (
        <InputFlex>
            {/* Questions. before submitting changes, check if data has changed */}
            <InputItem>
                <Tag>Question *</Tag>
                <textarea
                    onChange={(e) => {
                        const value = e.target.value
                        setQuestion((current: any) => {
                            syncQuestions({ ...current, title: value })
                            return { ...current, title: value }
                        })
                    }}
                    placeholder={currentQuestion.title}
                />
            </InputItem>

            <InputItem>
                <Tag>Learn More Link</Tag>
                <textarea
                    onChange={e => {
                        const value = adjustRequestPrefix(e.target.value)
                        setQuestion((current: any) => {
                            syncQuestions({ ...current, info_link: value })
                            return { ...current, info_link: value }
                        })
                    }}
                    placeholder={currentQuestion.info_link}

                />
            </InputItem>

            {/* Answer Choices */}
            <InputItem>
                <Tag>A*</Tag>
                <textarea
                    onChange={e => {
                        const value = e.target.value
                        setQuestion((current: any) => {
                            syncQuestions({ ...current, a: value })
                            return { ...current, a: value }
                        });
                    }}
                    placeholder={currentQuestion.a}

                />
            </InputItem>
            <InputItem>
                <Tag>B*</Tag>
                <textarea
                    onChange={e => {
                        const value = e.target.value
                        setQuestion((current: any) => {
                            syncQuestions({ ...current, b: value })
                            return { ...current, b: value }
                        });
                    }}
                    placeholder={currentQuestion.b}

                />
            </InputItem>
            <InputItem>
                <Tag>C</Tag>
                <textarea
                    onChange={e => {
                        const value = e.target.value
                        setQuestion((current: any) => {
                            syncQuestions({ ...current, c: value })
                            return { ...current, c: value }
                        });
                    }}
                    placeholder={currentQuestion.c}

                />
            </InputItem>
            <InputItem>
                <Tag>D</Tag>
                <textarea
                    onChange={e => {
                        const value = e.target.value
                        setQuestion((current: any) => {
                            syncQuestions({ ...current, d: value })
                            return { ...current, d: value }
                        });
                    }}
                    placeholder={currentQuestion.d}

                />
            </InputItem>
            <InputItem>
                <Tag>E</Tag>
                <textarea
                    onChange={e => {
                        const value = e.target.value
                        setQuestion((current: any) => {
                            syncQuestions({ ...current, e: value })
                            return { ...current, e: value }
                        });
                    }}
                    placeholder={currentQuestion.e}

                />
            </InputItem>
            <InputItem>
                <Tag>F</Tag>
                <textarea
                    onChange={e => {
                        const value = e.target.value
                        setQuestion((current: any) => {
                            syncQuestions({ ...current, f: value })
                            return { ...current, f: value }
                        });                        }}
                    placeholder={currentQuestion.f}

                />
            </InputItem>

            <Container>
                <Button style={{ width: 300 }} variant="contained" onClick={() => updateQuestions((current: any) => removeQuestion(current, currentQuestion))}>
                    Delete Question
                </Button>
            </Container>
        </InputFlex>
    );
};

const InputFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 60px;
`
