import React from "react";

import { StyledInput } from "../styles/global";

import { makeId } from "../utils/ui.utils";


interface Props {
  label: string;
  value: string;
  setValue: (value: any) => void;
  isValid?: (bool: boolean) => {};
  required?: boolean;
  requiredString?: string;
  setWidth?: number;
}

export const DateInput = ({ label, value, setValue, isValid, required, requiredString, setWidth = 250 }: Props) => {
  const validate = (value: any): boolean => {
    const valid = value !== undefined && value !== null && value !== '' && value !== 'Invalid date'
    if (isValid) {
      isValid(valid);
    }
    //error prop expects the opposite
    return !valid;
  }

  const helperText = (value: string): string | undefined => (required && value === '') || value === 'Invalid date' ? requiredString || `${label} required.` : undefined;
  return (
    <>
      <StyledInput
        setWidth={setWidth}
        id={`${makeId(label)}-input`}
        aria-label={label}
        type="datetime-local"
        label={label}
        required={required}
        error={validate(value)}
        helperText={helperText(value)}
        variant="outlined"
        onChange={(e: { target: { value: any; }; }) => {
          setValue(e.target.value)
        }}
        value={value}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};
