import { AxiosInstance, AxiosRequestConfig } from 'axios'

/**
 * This is a logical wrapper around {@link axios} so we can inject for testing. Also provides a level of convenience
 * wrappers for return objects.
 */
export class HttpClient {
  // eslint-disable-next-line no-useless-constructor
  constructor(public http: AxiosInstance) {
  }

  get = <T>(path: string, config?: AxiosRequestConfig): Promise<T> => this.http.get<T>(
    path,
    config,
  ).then((r) => r.data)

  put = <T, B = {}>(
    path: string,
    body: B,
    config?: AxiosRequestConfig,
  ): Promise<T> => this.http.put<T>(path, body, config)
    .then((r) => r.data)

  post = <T, B = {}>(
    path: string,
    body?: B,
    config?: AxiosRequestConfig,
  ): Promise<T> => this.http.post<T>(path, body, config)
    .then((r) => r.data)

  delete = <T, B = {}>(
    path: string,
    body?: B,
    config?: AxiosRequestConfig,
  ): Promise<T> => this.http.delete(path, {
    data: body,
    ...config,
  }).then((r) => r.data)
}
