import { takeEvery } from 'redux-saga/effects'
import { AppActions } from './app.actions'

function* exampleActionSaga() {
  console.log('EXAMPLE ACTION FIRED')
  yield 5
}

export function* appSagas() {
  yield takeEvery(AppActions.ExampleAction, exampleActionSaga)
}
