import React, { useState, useEffect } from "react";
import styled from "styled-components";

import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { InputItem, Tag } from "../styles/global";
import { NumberInput } from './NumberInput'
import { PercentInput } from './PercentInput'

import { Symptoms } from './Symptoms'
import { getSymptoms } from "../utils/api.utils";


import  { InfectionRange, APISymptom, UnitType } from '../models'
import { pickTimeUnit, isPlural } from '../utils/ui.utils';
import { unitTimeConverter } from '../utils/date.utils';

interface Props {
  pathogen: any;
  simulation?: any | undefined;
  setPathogen: any;
  timeStep?: UnitType;
  currentUnitType?: UnitType;
}

export const PathogenForm = ({ pathogen, simulation, setPathogen, timeStep, currentUnitType }: Props) => {
  let timeText =  (timeStep && pickTimeUnit(timeStep)) || 'time step';
  const [symptoms, setSymptoms] = useState<APISymptom[]>([]);
  const symptomsLoaded = symptoms && symptoms.length >= 1;

  // Param check to support new pathogens
  if (pathogen.infection_prob.from_reinfection === undefined) pathogen.infection_prob.from_reinfection = 0;

  const fetchSymptoms = () => {
    getSymptoms()
    .then((result: any) => {
      const payload = result as any;
      setSymptoms(payload);
    })
    .catch((err: any) => {
      console.error(err);
      setSymptoms([])
    });
  };

  useEffect(() => {
    fetchSymptoms();
  }, []);

  useEffect(() => {
    if (currentUnitType) {
      setPathogen((current: any) => {
        return {
          ...current,
          infection_info: {
            ...current.infection_info,
            asym_period: unitTimeConverter(currentUnitType, current.infection_info.asym_period, timeStep),
            mild_period: unitTimeConverter(currentUnitType, current.infection_info.mild_period, timeStep),
            severe_period: unitTimeConverter(currentUnitType, current.infection_info.severe_period, timeStep),
            exposed_period: unitTimeConverter(currentUnitType, current.infection_info.exposed_period, timeStep),
            onset_time_mean: unitTimeConverter(currentUnitType, current.infection_info.onset_time_mean, timeStep),
            onset_time_sdev: unitTimeConverter(currentUnitType, current.infection_info.onset_time_sdev, timeStep),
          },
          fpos_info: {
            ...current.fpos_info,
            mean: unitTimeConverter(currentUnitType, pathogen.fpos_info.mean, timeStep)
          }
        };
      });
    }
  }, [])

  return (
    <div>
      <PathogenItem>
        <InputItem>
          <NumberInput
            min={1}
            setWidth={90}
            title='Basic reproduction number'
            value={pathogen.seed_parameters && pathogen.seed_parameters.basic_reproduction_number}
            setValue={(value: string) => {
              setPathogen((current: any) => {
                return {
                  ...current,
                  seed_parameters: {
                    ...current.seed_parameters,
                    basic_reproduction_number: value
                  }
                };
              });
            }}            
            required
            requiredString="Reproduction number required."
          />
        </InputItem>
      </PathogenItem>
      <PathogenItem>
        <Tag>Probability of Infection from Source</Tag>
         <PercentInput
          isDecimal
          required
          requiredString="Probability required."
          setWidth={90}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_prob: {
                  ...current.infection_prob,
                  from_source: value
                }
              };
            });
          }}
          value={pathogen.infection_prob.from_source}
        />
      </PathogenItem>
      <PathogenItem>
        <Tag>Probability of Reinfection</Tag>
         <PercentInput
          isDecimal
          required
          requiredString="Probability required."
          setWidth={90}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_prob: {
                  ...current.infection_prob,
                  from_reinfection: value
                }
              };
            });
          }}
          value={pathogen.infection_prob.from_reinfection}
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Exposed to mild infection ratio'
          max={0}
          setWidth={90}
          value={pathogen.seed_parameters && pathogen.seed_parameters.exposed_to_inf_mild_ratio}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                seed_parameters: {
                  ...current.seed_parameters,
                  exposed_to_inf_mild_ratio: value
                }
              };
            });
          }}
          required
          requiredString="Ratio required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Mean of symptom onset to mild Infection ratio'
          min={0}
          setWidth={90}
          value={pathogen.seed_parameters && pathogen.seed_parameters.mean_symp_onset_to_inf_mild_ratio}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                seed_parameters: {
                  ...current.seed_parameters,
                  mean_symp_onset_to_inf_mild_ratio: value
                }
              };
            });
          }}
          required
          requiredString="Ratio required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Standard deviation of symptom onset to mild infection ratio'
          min={0}
          setWidth={90}
          value={pathogen.seed_parameters && pathogen.seed_parameters.sdev_symp_onset_to_inf_mild_ratio}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                seed_parameters: {
                  ...current.seed_parameters,
                  sdev_symp_onset_to_inf_mild_ratio: value
                }
              };
            });
          }}
          required
          requiredString="Standard deviation required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Severe infection to mild infection ratio'
          min={0}
          setWidth={90}
          value={pathogen.seed_parameters && pathogen.seed_parameters.inf_severe_to_inf_mild_ratio}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                seed_parameters: {
                  ...current.seed_parameters,
                  inf_severe_to_inf_mild_ratio: value
                }
              };
            });
          }}
          required
          requiredString="Standard deviation required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Asymptomatic to mild infection ratio'
          min={0}
          setWidth={90}
          value={pathogen.seed_parameters && pathogen.seed_parameters.asym_to_inf_mild_ratio}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                seed_parameters: {
                  ...current.seed_parameters,
                  asym_to_inf_mild_ratio: value
                }
              };
            });
          }}
          required
          requiredString="Ratio required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Asymptomatic probability of infection factor'
          min={0}
          setWidth={90}
          value={pathogen.seed_parameters && pathogen.seed_parameters.asym_pinf_factor}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                seed_parameters: {
                  ...current.seed_parameters,
                  asym_pinf_factor: value
                }
              };
            });
          }}
          required
          requiredString="Probability factor required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Mild probability of infection factor'
          min={0}
          setWidth={90}
          value={pathogen.seed_parameters && pathogen.seed_parameters.mild_pinf_factor}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                seed_parameters: {
                  ...current.seed_parameters,
                  mild_pinf_factor: value
                }
              };
            });
          }}
          required
          requiredString="Probability factor required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Severe probability of infection factor'
          min={0}
          setWidth={90}
          value={pathogen.seed_parameters && pathogen.seed_parameters.severe_pinf_factor}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                seed_parameters: {
                  ...current.seed_parameters,
                  severe_pinf_factor: value
                }
              };
            });
          }}
          required
          requiredString="Probability factor required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Range of source'
          setWidth={90}
          value={pathogen.source_range}
          setValue={(value: string) => {
            setPathogen((current: any) => {
              return { ...current, source_range: value };
            });
          }}
          required
          requiredString="Range required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Mutation rate'
          setWidth={90}
          value={pathogen.mutation_rate}
          setValue={(value: string) => {
            setPathogen((current: any) => {
              return { ...current, mutation_rate: value };
            });
          }}
          required
          requiredString="Mutation Rate required."
        />
      </PathogenItem>
      {simulation &&
        <PathogenItem>
          <Tag>Probability of Infection from Asymptomatic Peer</Tag>
          <PercentInput
              required
              isDecimal
              requiredString="Probability required."
              setWidth={90}
              setValue={(value: number) => {
                setPathogen((current: any) => {
                  return {
                    ...current,
                    infection_prob: {
                      ...current.infection_prob,
                      from_asym_peer: value
                    }
                  };
                });
              }}
              value={pathogen.infection_prob.from_asym_peer}
            />
        </PathogenItem>
      }
      <PathogenItem>
        <NumberInput
          title='Prevalence of Asymptomatic Cases'
          max={1}
          setWidth={90}
          value={pathogen.infection_info.asym_prevalance}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_info: {
                  ...current.infection_info,
                  asym_prevalance: value
                }
              };
            });
          }}
          required
          requiredString="Prevalence required."
        />
      </PathogenItem>
      {simulation &&
       <>
          <PathogenItem>
            <NumberInput
              title='Asymptomatic Period'
              setWidth={150}
              value={pathogen.infection_info.asym_period}
              setValue={(value: number) => {
                setPathogen((current: any) => {
                  return {
                    ...current,
                    infection_info: {
                      ...current.infection_info,
                      asym_period: value
                    }
                  };
                });
              }}
              required
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(pathogen.infection_info.asym_period, timeText)}</InputAdornment>,
              }}
            />
          </PathogenItem>
          <PathogenItem>
            <Tag>Probability of Infection from Mild Peer</Tag>
            <PercentInput
                required
                isDecimal
                requiredString="Probability required."
                setWidth={90}
                setValue={(value: number) => {
                  setPathogen((current: any) => {
                    return {
                      ...current,
                      infection_prob: {
                        ...current.infection_prob,
                        from_mild_peer: value
                      }
                    };
                  });
                }}
                value={pathogen.infection_prob.from_mild_peer}
              />
          </PathogenItem>
          <PathogenItem>
            <NumberInput
              title='Mild Period'
              setWidth={150}
              value={pathogen.infection_info.mild_period}
              setValue={(value: number) => {
                setPathogen((current: any) => {
                  return {
                    ...current,
                    infection_info: {
                      ...current.infection_info,
                      mild_period: value
                    }
                  };
                });
              }}
              required
              requiredString="Period required."
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(pathogen.infection_info.mild_period, timeText)}</InputAdornment>,
              }}
            />
          </PathogenItem>
          <PathogenItem>
            <Tag>Probability of Infection from Severe Peer</Tag>
            <PercentInput
              required
              isDecimal
              requiredString="Probability required."
              setWidth={90}
              setValue={(value: number) => {
                setPathogen((current: any) => {
                  return {
                    ...current,
                    infection_prob: {
                      ...current.infection_prob,
                      from_severe_peer: value
                    }
                  };
                });
              }}
              value={pathogen.infection_prob.from_severe_peer}
            />
          </PathogenItem>
          <PathogenItem>
            <NumberInput
              title='Severe Period'
              setWidth={150}
              value={pathogen.infection_info.severe_period}
              setValue={(value: number) => {
                setPathogen((current: any) => {
                  return {
                    ...current,
                    infection_info: {
                      ...current.infection_info,
                      severe_period: value
                    }
                  };
                });
              }}
              required
              requiredString="Period required."
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(pathogen.infection_info.severe_period, timeText)}</InputAdornment>,
              }}
            />
          </PathogenItem>
        </>
      }
      <PathogenItem>
        <Tag>Probability of becoming severe after being mild</Tag>
        <PercentInput
          required
          isDecimal
          requiredString="Probability required."
          setWidth={90}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_info: {
                  ...current.infection_info,
                  severe_from_mild: value
                }
              };
            });
          }}
          value={pathogen.infection_info.severe_from_mild}
        />
      </PathogenItem>
      <PathogenItem>
        <Tag>Probability of becoming mild after being asymptomatic</Tag>
        <PercentInput
          required
          isDecimal
          requiredString="Probability required."
          setWidth={90}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_info: {
                  ...current.infection_info,
                  mild_from_asym: value
                }
              };
            });
          }}
          value={pathogen.infection_info.mild_from_asym}
        />
      </PathogenItem>
      {simulation &&
        <PathogenItem>
          <NumberInput
            title='Exposed Period'
            setWidth={150}
            value={pathogen.infection_info.exposed_period}
            setValue={(value: number) => {
              setPathogen((current: any) => {
                return {
                  ...current,
                  infection_info: {
                    ...current.infection_info,
                    exposed_period: value
                  }
                };
              });
            }}
            required
            InputProps={{
              endAdornment: <InputAdornment position="end">{isPlural(pathogen.infection_info.exposed_period, timeText)}</InputAdornment>,
            }}
          />
        </PathogenItem>
      }
      <PathogenItem>
        <Tag id="infection-range-label">Range value to trigger an infection</Tag>
        <Select
          labelId="infection-range-label"
          required
          id="infection-range-select"
          variant="outlined"
          value={pathogen.infection_range}
          onChange={(e) => {
            const { value } = e.target;
            setPathogen((current: any) => {
              return { ...current, infection_range: value };
            });
          }}
        >
          <MenuItem value={InfectionRange.IMMEDIATE}>Immediate</MenuItem>
          <MenuItem value={InfectionRange.STRONG}>Strong</MenuItem>
          <MenuItem value={InfectionRange.MEDIUM}>Medium</MenuItem>
          <MenuItem value={InfectionRange.WEAK}>Weak</MenuItem>
          <MenuItem value={InfectionRange.EXTREMELY_WEAK}>Extremely Weak</MenuItem>
        </Select>
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Prevalence of similar diseases'
          min={0}
          setWidth={90}
          value={pathogen.seed_parameters && pathogen.seed_parameters.prevalance_similar_disease}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                seed_parameters: {
                  ...current.seed_parameters,
                  prevalance_similar_disease: value
                }
              };
            });
          }}
          required
          requiredString="Prevalence required."
        />
      </PathogenItem>
      {simulation &&
        <PathogenItem>
          <Tag>Probability of false positive</Tag>
          <PercentInput
            required
            isDecimal
            requiredString="Probability required."
            setWidth={90}
            setValue={(value: number) => {
              setPathogen((current: any) => {
                return {
                  ...current,
                  fpos_info: {
                    ...current.fpos_info,
                    prob: value
                  }
                };
              });
            }}
            value={pathogen.fpos_info.prob}
          />
        </PathogenItem>
      }
      <PathogenItem>
        <NumberInput
          title='Mean time of false positive symptoms'
          setWidth={150}
          value={pathogen.fpos_info.mean}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                fpos_info: {
                  ...current.fpos_info,
                  mean: value
                }
              };
            });
          }}
          required
          requiredString="Mean time required."
          InputProps={{
            endAdornment: <InputAdornment position="end">{isPlural(pathogen.fpos_info.mean, timeText)}</InputAdornment>,
          }}
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Standard deviation of false positive symptoms lasting'
          setWidth={90}
          value={pathogen.fpos_info.sdev}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                fpos_info: {
                  ...current.fpos_info,
                  sdev: value
                }
              };
            });
          }}
          required
          requiredString="Standard deviation required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Minimum number of symptoms to have a false positive case'
          setWidth={130}
          value={pathogen.fpos_info.min_symptoms}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                fpos_info: {
                  ...current.fpos_info,
                  min_symptoms: value
                }
              };
            });
          }}
          required
          requiredString="Minimum required."
          InputProps={{
            endAdornment: <InputAdornment position="end">{isPlural(pathogen.fpos_info.min_symptoms, 'symptom')}</InputAdornment>,
          }}
        />
      </PathogenItem>
      {symptomsLoaded ?
        <Symptoms
          title='Symptom list for false positive case'
          currentSymptoms={pathogen.fpos_info.symptoms}
          allSymptoms={symptoms}
          setValue={(value: any[]) => {
            setPathogen((current: any) => {
              return {
                ...current,
                fpos_info: {
                  ...current.fpos_info,
                  symptoms: value
                }
              };
            });
          }}
        />
        :
        <div>No Symptoms found</div>
      }

      <PathogenItem>
        <NumberInput
          title='Minimum number of mild symptoms'
          setWidth={130}
          value={pathogen.infection_info.min_mild_symptoms}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_info: {
                  ...current.infection_info,
                  min_mild_symptoms: value
                }
              };
            });
          }}
          required
          requiredString="Minimum required."
          InputProps={{
            endAdornment: <InputAdornment position="end">{isPlural(pathogen.infection_info.min_mild_symptoms, 'symptom')}</InputAdornment>,
          }}
        />
      </PathogenItem>
      {symptomsLoaded ?
        <Symptoms
          title='Symptom list for mild case'
          currentSymptoms={pathogen.infection_info.mild_symptoms}
          allSymptoms={symptoms}
          setValue={(value: any[]) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_info: {
                  ...current.infection_info,
                  mild_symptoms: value
                }
              };
            });
          }}
        />
        :
        <div>No Symptoms found</div>
      }
      <PathogenItem>
        <NumberInput
          title='Minimum number of severe symptoms'
          setWidth={130}
          value={pathogen.infection_info.min_severe_symptoms}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_info: {
                  ...current.infection_info,
                  min_severe_symptoms: value
                }
              };
            });
          }}
          required
          requiredString="Minimum required."
          InputProps={{
            endAdornment: <InputAdornment position="end">{isPlural(pathogen.infection_info.min_severe_symptoms, 'symptom')}</InputAdornment>,
          }}
        />
      </PathogenItem>
      {symptomsLoaded ?
        <Symptoms
          title='Symptom list for severe case'
          currentSymptoms={pathogen.infection_info.severe_symptoms}
          allSymptoms={symptoms}
          setValue={(value: any[]) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_info: {
                  ...current.infection_info,
                  severe_symptoms: value
                }
              };
            });
          }}
        />
        :
        <div>No Symptoms found</div>
      }
      {simulation &&
        <>
          <PathogenItem>
            <NumberInput
              title='Onset time for symptoms (average)'
              setWidth={150}
              value={pathogen.infection_info.onset_time_mean}
              setValue={(value: number) => {
                setPathogen((current: any) => {
                  return {
                    ...current,
                    infection_info: {
                      ...current.infection_info,
                      onset_time_mean: value
                    }
                  };
                });
              }}
              required
              requiredString="Onset time required."
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(pathogen.infection_info.onset_time_mean, timeText)}</InputAdornment>,
              }}
            />
          </PathogenItem>
          <PathogenItem>
            <NumberInput
              title='Onset time for symptoms (standard deviation)'
              setWidth={150}
              value={pathogen.infection_info.onset_time_sdev}
              setValue={(value: number) => {
                setPathogen((current: any) => {
                  return {
                    ...current,
                    infection_info: {
                      ...current.infection_info,
                      onset_time_sdev: value
                    }
                  };
                });
              }}
              required
              requiredString="Onset time required."
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(pathogen.infection_info.onset_time_sdev, timeText)}</InputAdornment>,
              }}
            />
          </PathogenItem>
        </>
      }
      <PathogenItem>
        <NumberInput
          title='Mask Protection Factor'
          setWidth={90}
          value={pathogen.protection_info.mask_protection_factor}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                protection_info: {
                  ...current.protection_info,
                  mask_protection_factor: value
                }
              };
            });
          }}
          required
          requiredString="Protection factor required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='Mask Transmission Factor'
          setWidth={90}
          value={pathogen.protection_info.mask_transmission_factor}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                protection_info: {
                  ...current.protection_info,
                  mask_transmission_factor: value
                }
              };
            });
          }}
          required
          requiredString="Transmission factor required."
        />
      </PathogenItem>
      {/* <PathogenItem>
        <NumberInput
          title='Range of mask'
          value={pathogen.protection_info.mask_range}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                protection_info: {
                  ...current.protection_info,
                  mask_range: value
                }
              };
            });
          }}
          // required
          // requiredString="Range required."
        />
      </PathogenItem> */}
      <PathogenItem>
        <NumberInput
          title='PPE Protection Factor'
          setWidth={90}
          value={pathogen.protection_info.ppe_protection_factor}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                protection_info: {
                  ...current.protection_info,
                  ppe_protection_factor: value
                }
              };
            });
          }}
          required
          requiredString="Protection Factor required."
        />
      </PathogenItem>
      <PathogenItem>
        <NumberInput
          title='PPE Transmission Factor'
          setWidth={90}
          value={pathogen.protection_info.ppe_transmission_factor}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                protection_info: {
                  ...current.protection_info,
                  ppe_transmission_factor: value
                }
              };
            });
          }}
          required
          requiredString="Transmission factor required."
        />
      </PathogenItem>
      {/* <PathogenItem>
        <NumberInput
          title='How long a vaccine protects for'
          value={pathogen.vaccine_period}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                vaccine_period: value
              };
            });
          }}
          // required
          // requiredString="Range required."
        />
        <HelpText>hours</HelpText>
      </PathogenItem> */}
      {/* <PathogenItem>
        <NumberInput
          title='# of Vaccine doses available'
          value={pathogen.doses_per_vaccine}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                doses_per_vaccine: value
              };
            });
          }}
          // required
          // requiredString="Range required."
        />
      </PathogenItem> */}
      {/* <PathogenItem>
        <NumberInput
          title='Range of vaccine'
          value={pathogen.vaccine_range}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                vaccine_range: value
              };
            });
          }}
          // required
          // requiredString="Range required."
        />
      </PathogenItem> */}
      <PathogenItem>
        <Tag>Probability of recovering from a mild state</Tag>
        <PercentInput
          required
          isDecimal
          requiredString="Probability required."
          setWidth={90}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_info: {
                  ...current.infection_info,
                  recovery_from_mild: value
                }
              };
            });
          }}
          value={pathogen.infection_info.recovery_from_mild}
        />
      </PathogenItem>
      <PathogenItem>
        <Tag>Probability of recovering from a severe state</Tag>
        <PercentInput
          required
          isDecimal
          requiredString="Probability required."
          setWidth={90}
          setValue={(value: number) => {
            setPathogen((current: any) => {
              return {
                ...current,
                infection_info: {
                  ...current.infection_info,
                  recovery_from_severe: value
                }
              };
            });
          }}
          value={pathogen.infection_info.recovery_from_severe}
        />
      </PathogenItem>
    </div>
  );
};

export const PathogenItem = styled(InputItem)`
  label{
    width: 180px;
  }
  .MuiSelect-select  {
    width: 115px;
  }
`
