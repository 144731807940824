import React, { useState } from 'react';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import { Input } from '../components/Input';
import { NumberInput } from '../components/NumberInput';
import { HelpText } from '../styles/global';

interface Props {
    shopItems?: any,
    setValue: (value: any) => void;
}

export const ShopItem = ({ shopItems, setValue }: Props) => {
    const [shopItemList, setShopItemList] = useState(shopItems || []);

    return (
        <div>
            {shopItemList.map((shopItem: any, index: number) => {
                return (
                    <ShopItemContainer key={index}>
                        <Input
                            setValue={(value: string) => {
                                let newShopItemList = shopItemList;
                                newShopItemList[index].item_name = value;
                                setShopItemList(newShopItemList);
                                setValue(newShopItemList);
                            }}
                            value={shopItemList[index]?.item_name}
                            required
                            requiredString="Shop item name required"
                        />
                        <Line />
                        <NumberInput
                            setWidth={100}
                            setValue={(value: number) => {
                                let newShopItemList = shopItemList;
                                newShopItemList[index].item_price = value;
                                setShopItemList(newShopItemList);
                                setValue(newShopItemList);
                            }}
                            value={shopItemList[index]?.item_price}
                            required
                            requiredString="Price required"
                        />
                        <Line />
                        <button
                            onClick={() => {
                                let newShopItemList = shopItemList;
                                newShopItemList.splice(index, 1);
                                setShopItemList(newShopItemList);
                                setValue(newShopItemList);
                            }}
                        >
                            X
                        </button>
                    </ShopItemContainer>
                );
            })}
            <StyledButton
                size="large"
                variant="contained"
                disableElevation
                onClick={() => {
                    const newShopItemList = [...shopItemList];
                    newShopItemList.push({ item_name: "", item_price: 0 });
                    setShopItemList(newShopItemList);
                    setValue(newShopItemList);
                }}
            >
                Add Shop Item
            </StyledButton>
        </div>

    );
}

const StyledButton = styled(Button)`
  max-width: 280px;
  button {
    background: none;
  }
`;

const ShopItemContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
`;

const Line = styled.span`
  margin-left: 12px;
  margin-right: 12px;
  background-color: #000;
  height: 3px;
  width: 24px;
`;
