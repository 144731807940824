import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { QuestionData } from '../models'
import { Question } from '../components/Question'
import { PrimaryHeader, SecondaryHeader, InputItem, Tag, Container, CloseButton } from '../styles/global'
import { submitQuiz, submissionCheck } from '../utils/api.utils'
import Button from '@material-ui/core/Button'
import { Flex } from '../styles/global'

// QuizEditor page takes a single quiz and allows it to be edited.

interface Props {
  quiz: any
  shouldDisplay: any
  fetchQuizzes: any
}

export function QuizEditor({ quiz, shouldDisplay, fetchQuizzes }: Props) {
  const [currentQuiz, setQuiz] = useState(quiz);
  const [questions, setQuestions] = useState<QuestionData[]>(currentQuiz.questions);

  useEffect(() => {
    setQuiz( (targetQuiz: any) => ({ ...targetQuiz, questions: questions }) )
  }, [questions]);

  return (
    <>
      <Container>
        <Container>
          <PrimaryHeader>Edit Quiz</PrimaryHeader>
          <SecondaryHeader>Quiz Details</SecondaryHeader>
        </Container>
        <Container>
          <InputItem>
            <Tag>Quiz Title *</Tag>
            <textarea
                onChange={(e) => {
                    const value = e.target.value
                    setQuiz((current: any) => {
                        return { ...current, title: value }
                    })
                }}
                placeholder={currentQuiz.title}
                      />
          </InputItem>
          <InputItem>
            <Tag>Description *</Tag>
            <textarea
                onChange={(e) => {
                    const value = e.target.value
                    setQuiz((current: any) => {
                        return { ...current, description: value }
                    })
                }}
                placeholder={currentQuiz.description}
                      />
          </InputItem>
        </Container>
        <Container>
          <SecondaryHeader>Questions</SecondaryHeader>
        </Container>
        <div>
          {questions.map((questionItem: QuestionData, i: Number) => {
            return (
                <Question
                  key={questionItem.id.toString()}
                  question={questionItem}
                  updateQuestions={setQuestions}
                />
            );
          })}
        </div>
        <Button
          variant="contained"
          onClick={() => {
            console.log("adding new question");
            setQuestions((current: any) => [
              ...current,
              {
                id: uuidv4(),
                created_at: Date.now(),
                title: "",
                answer: "",
                a: "",
                b: "",
                c: "",
                d: "",
                e: "",
                f: "",
              }
            ]);
          }}
        >
          + New Question
        </Button>
        <ButtonWrapper justify="space-between">
          <Button
            variant="contained"
            style={{ background: "#8bc34a", color: "#FFF" }}
            onClick={() => {
              const validatedQuiz = submissionCheck(currentQuiz)
              if (!validatedQuiz) {
                // potentially wire up to error system
                // for now, simply keep user where they are.
                return
              }
              // send quiz to network
              submitQuiz(validatedQuiz)
                .then(response => {
                  fetchQuizzes()
                  return response
                })
                .catch(err => console.error(err))
              // launch snackbar/toast message based on network response
              shouldDisplay(false)
            }}
          >
            Save Quiz
          </Button>
          <CloseButton onClick={() => shouldDisplay(false)}>Close & Discard</CloseButton>
        </ButtonWrapper>
      </Container>
    </>
  );
}

const ButtonWrapper = styled(Flex)`
  padding-top: 120px;
  bottom: 0;
`
