import React from 'react'
import styled from 'styled-components'
import { routes } from '../routes'

import { configureAmplify } from '../utils/aws.utils'

export const App = () => {
  configureAmplify()
  return (
    <AppStyles>
      {routes}
    </AppStyles>
  )
}

const AppStyles = styled.div`
  font-family: Roboto, sans-serif;
`
