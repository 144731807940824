import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import Dialog from "@material-ui/core/Dialog";

import { Flex, PromptHeader, PromptBody, PromptButton, Prompt } from '../styles/global'
import { getSimulations, deleteSimulation } from '../utils/api.utils'
import { SimulationEditor } from './SimulationEditor'
import { Simulation } from '../models'
import { useIsComponentMounted } from '../utils/hooks.utils'

import defaultSimulation from '../data/defaultSimulation'

export const SimulationList = () => {

  const deleteIcon = require("../assets/delete.svg");

  const [filter, setFilter] = useState<string>('active')
  const [showEditScreen, setEditScreen] = useState(false)
  const [loading, setLoading] = useState(true)

  const [currentSimulation, setCurrentSimulation] = useState<any>()
  const [simulations, setSimulations] = useState<Simulation[]>([])

  const isComponentMounted = useIsComponentMounted();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchSimulations = () => {
    // eslint-disable-next-line no-unused-expressions
    getSimulations(filter)
      .then((result) => {
        setLoading(false)
        const payload = result as any
        setSimulations(sortBy(payload, 'created_at'))
      })
      .catch((err) => {
        setSimulations([])
        setLoading(false)
      })
  }

  const createSimulation = () => {
    setCurrentSimulation(defaultSimulation)
    setEditScreen(true)
  }

  const selectSimulation = (simulation: any) => {
    setCurrentSimulation(simulation)
    setEditScreen(true)
  }

  const handleSimulationDelete = (simulation: any) => {
    deleteSimulation(simulation.id)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    setCurrentSimulation(undefined);
    setShowDeleteModal(false);
    // delay call to fetch simulation for deletion to appear.
    setTimeout(() => fetchSimulations(), 1000);
    fetchSimulations();
  };

  const displayDeleteModal = (simulation: any) => {
    if (!simulation) {
      return;
    }
    setCurrentSimulation(simulation);
    // show modal
    setShowDeleteModal(true);
  };

  useEffect(() => {
    if (isComponentMounted.current) {
      fetchSimulations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  if (showEditScreen) {
    window.scrollTo(0, 0)
    return (
      <SimulationEditor
        shouldDisplay={setEditScreen}
        fetchSimulations={fetchSimulations}
        simulation={currentSimulation} />
    )
  }
  return (
    <div>
      <Flex justify="space-between" alignItems="center">
        <div>
          <TextButton active={filter === 'active'} onClick={() => setFilter('active')}>
            Active Simulations
          </TextButton>
          <TextButton active={filter === 'future'} onClick={() => setFilter('future')}>
            Future Simulations
          </TextButton>
          <TextButton active={filter === 'past'} onClick={() => setFilter('past')}>
            Past Simulations
          </TextButton>
          <TextButton active={filter === 'archived'} onClick={() => setFilter('archived')}>
            Archived
          </TextButton>
        </div>
        <Button variant="contained" color="primary" onClick={() => createSimulation()}>
          Add New
        </Button>
      </Flex>
      <div id="simulations-view">
        {!loading
          ? simulations && simulations.length >= 1
            ? simulations.map((item: any) => (
              <ListItem
                key={item.id + item.name}
                direction="row"
                justify="space-between"
              >
                <SimulationPreview
                  onClick={() => selectSimulation(item)}>
                  <SimulationTitle>
                    <ListTitle>{item.name}</ListTitle>
                    <p>{item.pathogen_name}</p>
                  </SimulationTitle>
                  <SimulationVenue>{item.venue}</SimulationVenue>
                  <div>
                    {item.size
                      && `${item.size} participants`}
                  </div>
                  <div>
                    {item.end_date && `Ends ${moment(item.end_date, 'YYYY-MM-DD HH:mm:ss').format('L')}`}
                  </div>
                </SimulationPreview>
                <OptionContainer alignItems="center">
                  <PromptButton
                    onClick={() => displayDeleteModal(item)}
                  >
                    <img src={deleteIcon} style={{ height: 30 }} alt="delete" />
                  </PromptButton>
                </OptionContainer>
              </ListItem>
            ))
            : <NotFound>No Simulations Found</NotFound>
          : <NotFound>Loading...</NotFound>}
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={showDeleteModal}
        >
          <Prompt direction="column" justify="center" alignItems="center">
            <PromptHeader>
              Are you sure that you would like to delete this simulation?
            </PromptHeader>
            <PromptBody>Deleting a simulation is irreversible.</PromptBody>
            <Flex>
              <PromptButton
                onClick={() => setShowDeleteModal(false)}
                variant="contained"
              >
                Return
              </PromptButton>
              <PromptButton
                textcolor={"white"}
                tint={"#c10000"}
                onClick={() => handleSimulationDelete(currentSimulation)}
                variant="contained"
              >
                Delete
              </PromptButton>
            </Flex>
          </Prompt>
        </Dialog>
      </div>
    </div>
  )
}

const ListTitle = styled.h4`
  font-weight: 600;
  padding-bottom: 8px;
  font-size: 24px;
`

const NotFound = styled.h4`
  font-weight: 600;
  margin-top: 120px;
  font-size: 24px;
  text-align: center;
`

const ListItem = styled(Flex)`
  margin-top: 20px;
  padding: 16px;
  background: #F8F8F8;
  color: #000;
  border: solid 1px #666;
  border-radius: 8px;
`

const SimulationVenue = styled.div`
  text-transform: capitalize;
`

const SimulationTitle = styled.div`
  text-align: left !important;
`

const TextButton = styled(({ active, ...others }) => <Button {...others} />) <{ active: boolean }>`
  background: none;
  margin: 0 8px !important;
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')} !important;
`
const SimulationPreview = styled(Flex)`
  flex-direction: row;
  width: 100%;
  padding: 24px 24px;
  justify-content: space-between;
  div {
    display: block;
    width: 100%;
    text-align: right;
  }
`

const OptionContainer = styled(Flex)`
  /* padding: 20px; */

  > ${PromptButton} {
    margin: 0 16px !important;
    height: 50px;
  }
`;