import React from 'react'
import styled from 'styled-components'
import { Menu } from './Menu'
import { AuthCheck } from './AuthCheck'

export const DashboardWrapper = (ChildComponent: any) => {
  return () => (
    <div>
      <AuthCheck />
      <Menu />
      <Wrapper>
        <ChildComponent />
      </Wrapper>
    </div>
  )
}


const Wrapper = styled.div`
  margin-left: 200px;
  padding: 20px;
`
