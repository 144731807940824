/**
 * Name should be name of feature
 */
import { AppActions, AppActionTypes } from './app.actions'


export interface AppExampleState {
  isCalled: boolean
}

const defaultAppState: AppExampleState = {
  isCalled: false,
}

export const appReducer = (state: AppExampleState = defaultAppState, action: AppActionTypes): AppExampleState => {
  switch (action.type) {
    case AppActions.ExampleAction:
      return {
        ...state,
        isCalled: true,
      }
    default:
      return state
  }
}
