import { UnitType, VenueType } from '../models'
import { v4 as uuidv4 } from 'uuid';
import { detectTimezone, generateDate } from '../utils/date.utils'

export default {
  name: "",
  uuid: uuidv4(),
  code: "",
  start_date: generateDate(),
  end_date: generateDate(3),
  activity_url: "",
  archived: false,
  size: 10,
  venue: VenueType.CLASSROOM,
  unit_type: UnitType.HOUR,
  time_step: 2,
  epi_generations: 1,
  number_of_contacts: 1,
  number_of_contacts_in: 10,
  time_zone: detectTimezone(),
  time_zone_IANA: Intl.DateTimeFormat().resolvedOptions().timeZone,
  ui_style: 1,
  join_window: 100,
  qr_support: false,
  food_support: false,
  food_interval: 0.5,
  sequence_support: false,
  genome_coverage: 100,
  score_support: true,
  debug_support: false,
  leaderboard_support: false,
  raffle_support: false,
  use_location: true,
  use_beacons: true,
  number_of_strains: 1,
  is_modified: false,
  pathogen_id: null,
  pathogen_name: "",
  pathogen: {},
  case_zero_count: 1,
  case_zero_population_size: 10,
  case_zero_adapt_time: 1,
  case_zero_infect_time: 2,
  rewards: {
    ppe_period: 0.5,
    mask_period: 1,
    min_questions: 3,
    min_score_mask: 60,
    min_score_ppe: 100,
  },
  group_support: false,
  random_group: false,
  //quarantine_support: false,
  shop_support: false,
  intervention_support: false,
  init_score_min: 0,
  init_score_max: 10,
  points_answer: 1,
  points_symptomatic: 0,
}
