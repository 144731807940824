import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, Link } from 'react-router-dom'
import { Paper, Button } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import { Flex, SecondaryHeader } from '../styles/global'
import { Input } from '../components/Input'

import { AUTH_USER_TOKEN_KEY } from '../utils/auth.utils'

interface UserCredentials {
  username: string
  password: string
  // if reset is needed
  password1: string
  password2: string
}

export const Login = () => {
  const [formData, setFormData] = useState<UserCredentials>({
    username: '', password: '', password1: '', password2: '',
  })
  const [formTouched, setFormTouched] = useState<{username: boolean; password: boolean; password1: boolean; password2: boolean}>({
    username: false, password: false, password1: false, password2: false,
  })

  const [isLoading, setLoading] = useState(false)
  const [newPasswordRequired, newChangePasswordRequired] = useState(false)
  const [user, setUser] = useState({})

  const history = useHistory()

  const isLoginValid = formData && formData.password !== '' && formData.username !== ''
  const isPasswordResetValid = formData && formData.password1 !== '' && formData.password2 !== '' && formData.password1 === formData.password2

  const sendCredentials = () => {
    setLoading(true)
    Auth.signIn(formData.username, formData.password)
      .then(async (user) => {
        if (user.signInUserSession !== null) {
          localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.idToken.jwtToken || '')
          history.push('/')
        } else {
          newChangePasswordRequired(true)
          setUser(user)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        localStorage.setItem(AUTH_USER_TOKEN_KEY, '')
        console.error(err)
        alert(err.message)
        // cognito login error. dispatch toast and error text
      })
  }

  const resetPassword = () => {
    setLoading(true)
    Auth.completeNewPassword(user, formData.password1)
      .then(async (user) => {
        if (user.signInUserSession !== null) {
          localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.idToken.jwtToken || '')
          // annoyingly long timout to wait for localStorage
          setTimeout(() => {
            history.push('/')
          }, 3000)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        localStorage.setItem(AUTH_USER_TOKEN_KEY, '')
        console.error(err)
        alert(err.message)
        // cognito login error. dispatch toast and error text
      })
  }

  return (
    <Container>
      <LoginBox elevation={1}>
        <Flex direction="column">
          {newPasswordRequired
            ? (
              <>
                <SecondaryHeader>Password Reset Required</SecondaryHeader>
                <StyledInput
                  label="Password"
                  setWidth="100%"
                  type="password"
                  required={formTouched.password1}
                  requiredString="Password required."
                  setValue={(value: string) => {
                    setFormTouched({ ...formTouched, password1: true })
                    setFormData(() => ({ ...formData, password1: value }))
                  }}
                  value={formData.password1} />
                <StyledInput
                  label="Password"
                  setWidth="100%"
                  type="password"
                  required={formTouched.password2}
                  requiredString="Password required."
                  setValue={(value: string) => {
                    setFormTouched({ ...formTouched, password2: true })
                    setFormData(() => ({ ...formData, password2: value }))
                  }}
                  value={formData.password2} />
                <Button disabled={!isPasswordResetValid || isLoading} onClick={() => resetPassword()} variant="contained" color="primary">
                  Reset Password
                </Button>
              </>
            )
            : (
              <>
                <SecondaryHeader>Welcome to Operation Outbreak</SecondaryHeader>
                <StyledInput
                  label="E-Mail"
                  setWidth="100%"
                  type="email"
                  required={formTouched.username}
                  requiredString="E-Mail required."
                  setValue={(value: string) => {
                    setFormTouched({ ...formTouched, username: true })
                    setFormData(() => ({ ...formData, username: value }))
                  }}
                  value={formData.username} />
                <StyledInput
                  label="Password"
                  setWidth="100%"
                  type="password"
                  required={formTouched.password}
                  requiredString="Password required."
                  setValue={(value: string) => {
                    setFormTouched({ ...formTouched, password: true })
                    setFormData(() => ({ ...formData, password: value }))
                  }}
                  value={formData.password} />
                <Button disabled={!isLoginValid || isLoading} onClick={() => sendCredentials()} variant="contained" color="primary">
                  Sign In
                </Button>
                <StyledLink to="/forgot-password">
                  I forgot my password
                </StyledLink>
              </>
            )}
        </Flex>
      </LoginBox>
    </Container>
  )
}

const StyledInput = styled(Input)`
  margin-bottom: 24px !important;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
  text-align: center;
  margin-top: 24px !important;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
  background: #333;
`

const LoginBox = styled(Paper)`
  padding: 36px;
  display: flex;
  justify-content: center;
  ${Flex} {
    ${StyledInput} {
      margin-bottom: 16px;
    }
    ${SecondaryHeader} {
      margin-bottom: 30px;
      text-align: center;
    }
  }
`
