import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ViewContainer } from '../utils/ui.utils'
import { Container } from '../styles/global'
import { submitPathogen } from '../utils/api.utils'
import Button from '@material-ui/core/Button'

import Fab from '@material-ui/core/Fab';

import { PathogenForm } from '../components/PathogenForm'
import { SequenceForm } from '../components/SequenceForm'
import { Input } from '../components/Input'

import { InputItem } from '../styles/global'

interface Props {
  pathogen: any
  shouldDisplay: any
  fetchPathogens: any
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

export function PathogenEditor({ pathogen, shouldDisplay, fetchPathogens }: Props) {
  const [currentPathogen, setPathogen] = useState(pathogen);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [tabValue, setTab] = useState<number>(0);


  useEffect(() => {
    setPathogen( (targetPathogen: any) => ({ ...targetPathogen }) )
  }, []);

  const handleTabChange = (event: any, newValue: number) => {
    setTab(newValue);
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function savePathogen() {
    // send Pathogen to network
    submitPathogen(currentPathogen)
      .then((response: any) => {
        fetchPathogens()
        shouldDisplay(false)
        return response
      }).catch((err: any) => console.error(err))
  }

  return (
    <>
    <ContainerWrapper>
      <Container>
        <Container>
          <Button onClick={()=> shouldDisplay(false)}>{`< ${currentPathogen.name || 'Untitled Pathogen'}`}</Button>
        </Container>
        <ThemedTabs value={tabValue} onChange={handleTabChange} aria-label="simulation settings categories">
          <Tab label="Properties" {...a11yProps(0)} />
          <Tab label="Sequence" {...a11yProps(1)} />
        </ThemedTabs>
        
        <ThemedTabPanel value={tabValue} index={0}>
          <InputItem>
            <Input
              title="Pathogen Name"
              required
              isValid={valid => setIsValid(valid)}
              requiredString="Name required."
              value={currentPathogen.name}
              setValue={(value: string) => {
                setPathogen((current: any) => {
                  return { ...current, name: value };
                });
              }}
            />
          </InputItem>
          <PathogenForm
            pathogen={currentPathogen}
            setPathogen={setPathogen}
          />
        </ThemedTabPanel>
        <ThemedTabPanel value={tabValue} index={1}>
          <SequenceForm
            id={currentPathogen.id}
          />
        </ThemedTabPanel>
        {tabValue !== 1 &&
          <FabWrapper
            variant="extended"
            color="primary"
            disabled={!isValid}
            onClick={() => savePathogen()}
          >
            Save Pathogen
          </FabWrapper>
        }
      </Container>
    </ContainerWrapper>
    </>
  );
}

const ContainerWrapper = styled(ViewContainer)`
  background: #FFF;
`;

const FabWrapper = styled(Fab)`
  position: fixed !important;
  right: 40px;
  bottom: 40px;
`;

const ThemedTabs = styled(Tabs)`
  margin-top: 24px;
  margin-bottom: 24px;
`

const ThemedTabPanel = styled(TabPanel)`
  margin-top: 24px;
`