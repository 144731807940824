import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Input } from '../components/Input'
import { NumberInput } from '../components/NumberInput'
import { DateInput } from '../components/DateInput'
import { UnitType, VenueType, PushType, UIStyle, APIIntervention } from '../models'
import simulation from '../data/defaultSimulation'
import { pickTimeUnit, isPlural } from '../utils/ui.utils'
import {
  serverToLocalDate,
  localToServerDate,
  unitTimeConverter,
  getTimeZonesList,
  gmtFormat
} from '../utils/date.utils'
import { InputItem, Tag, HelpText } from '../styles/global';
import { Blackout } from './blackOutPeriods';
import { Group } from './groupSelections';
import { ShopItem } from './shopItems';

import { Interventions } from './Interventions'
import { getInterventions } from "../utils/api.utils";

interface Props {
  currentSimulation: any;
  setSimulation: any;
}

export const GlobalSettings = ({ currentSimulation, setSimulation }: Props) => {
  const hasCaseZeroVar = currentSimulation.case_zero_count && currentSimulation.case_zero_population_size &&
    currentSimulation.case_zero_infect_time && currentSimulation.case_zero_adapt_time
  const [infectionMethod, setInfectionMethod] = useState<any>(hasCaseZeroVar ? PushType.AUTO : PushType.MANUAL);
  const [foodOptions, setFoodOptions] = useState<any>(currentSimulation.food_support);
  const [sequenceOptions, setSequenceOptions] = useState<any>(currentSimulation.sequence_support);
  //const [scoreOptions, setScoreOptions] = useState<any>(currentSimulation.score_support);
  const [groupOptions, setGroupOptions] = useState<any>(currentSimulation.group_support);
  //const [quarantineOptions, setQuarantineOptions] = useState<any>(currentSimulation.quarantine_support);
  const [shopOptions, setShopOptions] = useState<any>(currentSimulation.shop_support);
  const [interventionOptions, setInterventionOptions] = useState<any>(currentSimulation.intervention_support);
  const [tzOptions, setTzOptions] = useState<any[]>([]);
  //const [tzSelection, setTzSelection] = useState<string>("");
  const showInfectionMethod = infectionMethod === PushType.AUTO;
  const showFoodOptions = foodOptions === true;
  const showSequenceOptions = sequenceOptions === true;
  //const showScoreOptions = scoreOptions === true;
  const showGroupOptions = groupOptions === true;
  //const showQuarantineOptions = quarantineOptions === true;
  const showShopOptions = shopOptions === true;
  const showInterventionOptions = interventionOptions === true;
  let timeText = pickTimeUnit(currentSimulation.unit_type);

  const [interventions, setInterventions] = useState<APIIntervention[]>([]);
  const interventionsLoaded = interventions && interventions.length >= 1;

  // Param check to support new simulations
  if (currentSimulation.qr_support === undefined) currentSimulation.qr_support = false;
  if (currentSimulation.leaderboard_support === undefined) currentSimulation.leaderboard_support = false;
  if (currentSimulation.raffle_support === undefined) currentSimulation.raffle_support = false;
  if (currentSimulation.food_support === undefined) currentSimulation.food_support = false;
  if (currentSimulation.food_interval === undefined) currentSimulation.food_interval = 0.5;
  if (currentSimulation.sequence_support === undefined) currentSimulation.sequence_support = false;
  if (currentSimulation.genome_coverage === undefined) currentSimulation.genome_coverage = 100;
  if (currentSimulation.score_support === undefined) currentSimulation.score_support = true;
  if (currentSimulation.debug_support === undefined) currentSimulation.debug_support = false;
  if (currentSimulation.ui_style === undefined) currentSimulation.ui_style = 1;
  if (currentSimulation.time_zone_IANA === undefined) currentSimulation.time_zone_IANA = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (currentSimulation.group_support === undefined) currentSimulation.group_support = false;
  if (currentSimulation.random_group === undefined) currentSimulation.random_group = false;
  //if (currentSimulation.quarantine_support === undefined) currentSimulation.quarantine_support = false;
  if (currentSimulation.shop_support === undefined) currentSimulation.shop_support = false;
  if (currentSimulation.join_window === undefined) currentSimulation.join_window = 100;
  if (currentSimulation.intervention_support === undefined) currentSimulation.intervention_support = false;
  if (currentSimulation.interventions === undefined) currentSimulation.interventions = [];

  const fetchInterventions = () => {
    getInterventions()
    .then((result: any) => {
      const payload = result as any;
      setInterventions(payload);
    })
    .catch((err: any) => {
      console.error(err);
      setInterventions([])
    });
  };

  useEffect(() => {
    setTzOptions(getTimeZonesList());
    fetchInterventions();
    //setTzSelection(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  return (
    <div>
      <InputItem>
        <Input
          title="Simulation Name"
          placeholder="My Simulation"
          required
          requiredString="Name required."
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, name: value };
            });
          }}
          value={currentSimulation.name}
        />
      </InputItem>
      <InputItem>
        <Input
          title="Simulation Code"
          placeholder="mysimcode"
          required
          requiredString="Code required."
          replaceCharacters={(value: string) => value.replace(/[^0-9a-z]/gi, '')}
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, code: value };
            });
          }}
          value={currentSimulation.code}
        />
        <HelpText>(letters and numbers only)</HelpText>

      </InputItem>
      <InputItem>
        <Input
          title="Activity URL"
          placeholder="https://example.com"
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, activity_url: value };
            });
          }}
          value={currentSimulation.activity_url}
        />
      </InputItem>
      <InputItem>
        <Tag id="ui-style-label">UI Style</Tag>
        <Select
          labelId="ui-style-label"
          required
          id="ui-style-select"
          variant="outlined"
          value={currentSimulation.ui_style}
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, ui_style: value };
            });
          }}
        >
          <MenuItem value={UIStyle.DEFAULT}>Default</MenuItem>
          <MenuItem value={UIStyle.FATHOM}>Fathom</MenuItem>
        </Select>
      </InputItem>
      <InputItem>
        <Tag>Select Time Zone</Tag>
        <Select
          labelId="time-zone-label"
          required
          id="tz-select"
          variant="outlined"
          value={currentSimulation.time_zone_IANA}
          //defaultValue={tzSelection}
          onChange={(e) => {
            const { value }: any = e.target;
            //setTzSelection(value);
            setSimulation((current: any) => {
              return { ...current, time_zone: gmtFormat(value), time_zone_IANA: value };
            });
          }}
        >
          {tzOptions.map((tz, index) => <MenuItem key={index} value={tz}>{tz}</MenuItem>)}
        </Select>
      </InputItem>
      <InputItem>
        <Tag>Start & End *</Tag>
        <DateInput
          label="Start Date/Time"
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, start_date: localToServerDate(value) };
            });
          }}
          value={serverToLocalDate(currentSimulation.start_date)}
          required
          requiredString="Start date required."
        />
        <Line />
        <DateInput
          label="End Date/Time"
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, end_date: localToServerDate(value) };
            });
          }}
          value={serverToLocalDate(currentSimulation.end_date)}
          required
          requiredString="End date required."
        />
      </InputItem>
      <InputItem>
        <Tag>Add Blackout</Tag>
        <Blackout
          blackouts={currentSimulation.pauseDates}
          setValue={(value: any[]) => {
            setSimulation((current: any) => {
              return { ...current, pauseDates: value };
            });
          }}
        />
      </InputItem>
      <InputItem>
        <NumberInput
          title="Total Size of Simulation"
          setWidth={102}
          required
          requiredString="Total size required."
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, size: value, case_zero_population_size: value };
            });
          }}
          value={currentSimulation.size}
        />
        <HelpText>participants</HelpText>
      </InputItem>
      <InputItem>
        <Tag id="venue-label">Venue</Tag>
        <Select
          labelId="venue-label"
          required
          id="venue-select"
          variant="outlined"
          value={currentSimulation.venue}
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, venue: value };
            });
          }}
        >
          <MenuItem value={VenueType.CLASSROOM}>Classroom</MenuItem>
          <MenuItem value={VenueType.MEDIUM}>Medium</MenuItem>
          <MenuItem value={VenueType.LARGE}>Large</MenuItem>
        </Select>
      </InputItem>
      <InputItem>
        <NumberInput
          title="Number of Strains"
          required
          min={0}
          max={1}
          requiredString="Number of strains required."
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, number_of_strains: value };
            });
          }}
          value={currentSimulation.number_of_strains}
        />
      </InputItem>
      <InputItem>
        <NumberInput
          title="Join Window Percentage"
          required
          min={1}
          max={100}
          requiredString="Number required."
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, join_window: value };
            });
          }}
          value={currentSimulation.join_window}
        />
      </InputItem>
      <InputItem>
        <Tag id="unit-label">Time unit of time-related parameters</Tag>
        <Select
          labelId="unit-label"
          id="unit-type"
          variant="outlined"
          value={currentSimulation.unit_type}
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return {
                ...current,
                previousType: currentSimulation.unit_type,
                unit_type: value,
                case_zero_adapt_time: unitTimeConverter(current.unit_type, current.case_zero_adapt_time, value),
                case_zero_infect_time: unitTimeConverter(current.unit_type, current.case_zero_infect_time, value),
                quarantine_interval: unitTimeConverter(current.unit_type, current.quarantine_interval, value),
                quarantine_wait_time: unitTimeConverter(current.unit_type, current.quarantine_wait_time, value),
                rewards: {
                  ...current.rewards,
                  ppe_period: unitTimeConverter(current.unit_type, current.rewards.ppe_period, value),
                  mask_period: unitTimeConverter(current.unit_type, current.rewards.mask_period, value)
                }
              };
            });
          }}
        >
          <MenuItem value={UnitType.SECOND}>{pickTimeUnit(UnitType.SECOND)}s</MenuItem>
          <MenuItem value={UnitType.MINUTE} >{pickTimeUnit(UnitType.MINUTE)}s</MenuItem>
          <MenuItem value={UnitType.HOUR} >{pickTimeUnit(UnitType.HOUR)}s</MenuItem>
          <MenuItem value={UnitType.DAY} >{pickTimeUnit(UnitType.DAY)}s</MenuItem>
        </Select>
      </InputItem>
      <InputItem>
        <NumberInput
          title="Simulation time step"
          required
          requiredString="Simulation time step required."
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, time_step: value };
            });
          }}
          value={currentSimulation.time_step}
          InputProps={{
            endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.time_step, pickTimeUnit(UnitType.SECOND))}</InputAdornment>,
          }}
        />
      </InputItem>
      <InputItem>
        <NumberInput
          title="EPI Generations"
          required
          requiredString="EPI generations required."
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, epi_generations: value };
            });
          }}
          value={currentSimulation.epi_generations}
        />
      </InputItem>
      <InputItem>
        <NumberInput
          title="Contact Rate"
          setWidth={50}
          required
          requiredString="Contact rate required."
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, number_of_contacts: value };
            });
          }}
          value={currentSimulation.number_of_contacts}
        />
        <TextSpacer> {isPlural(currentSimulation.number_of_contacts, 'contact')} in </TextSpacer>
        <NumberInput
          required
          setWidth={120}
          requiredString="Contact rate required."
          setValue={(value: string) => {
            setSimulation((current: any) => {
              return { ...current, number_of_contacts_in: value };
            });
          }}
          value={currentSimulation.number_of_contacts_in}
          InputProps={{
            endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.number_of_contacts_in, pickTimeUnit(UnitType.MINUTE))}</InputAdornment>,
          }}
        />
      </InputItem>
      <InputItem>
        <Tag id="push-label">Push Infection Method</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            value === PushType.MANUAL ?
              setSimulation((current: any) => {
                const { case_zero_count, case_zero_population_size, case_zero_adapt_time, case_zero_infect_time, ...modifiedSimulation } = current
                return modifiedSimulation;
              })
              :
              setSimulation((current: any) => {
                return {
                  ...current,
                  case_zero_count: simulation.case_zero_count,
                  case_zero_population_size: simulation.case_zero_population_size,
                  case_zero_adapt_time: simulation.case_zero_adapt_time,
                  case_zero_infect_time: simulation.case_zero_infect_time
                };
              })
            setInfectionMethod(value)

          }}
          row
          aria-label="Push Infection Method"
          name="position"
          value={infectionMethod}
        >
          <FormControlLabel value={PushType.MANUAL} control={<Radio color="primary" />} label="Manual" />
          <FormControlLabel value={PushType.AUTO} control={<Radio color="primary" />} label="Automatic" />
        </RadioGroup>
      </InputItem>

      {showInfectionMethod &&
        <>
          <InputItem>
            <NumberInput
              title="Number of index cases"
              setWidth={102}
              required
              requiredString="Number required."
              setValue={(value: string) => {
                setSimulation((current: any) => {
                  return { ...current, case_zero_count: value };
                });
              }}
              value={currentSimulation.case_zero_count}
            // InputProps={{
            // endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.case_zero_count,'person', 'people')}</InputAdornment>,
            // }}
            />
            <HelpText>people</HelpText>
          </InputItem>

          <InputItem>
            <NumberInput
              title="Initial population size"
              setWidth={102}
              required
              requiredString="Number required."
              setValue={(value: string) => {
                setSimulation((current: any) => {
                  return { ...current, case_zero_population_size: value };
                });
              }}
              value={currentSimulation.case_zero_population_size}
            // InputProps={{
            // endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.case_zero_population_size,'person', 'people')}</InputAdornment>,
            // }}
            />
            <HelpText>people</HelpText>
          </InputItem>

          <InputItem>
            <NumberInput
              title="Time to estimate population size"
              setWidth={200}
              required
              requiredString="Time required."
              setValue={(value: string) => {
                setSimulation((current: any) => {
                  return { ...current, case_zero_adapt_time: value };
                });
              }}
              value={currentSimulation.case_zero_adapt_time}
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.case_zero_adapt_time, timeText)}</InputAdornment>,
              }}
            />
          </InputItem>
          <InputItem>
            <NumberInput
              title="Time to seed index cases"
              setWidth={200}
              required
              requiredString="Time required."
              setValue={(value: string) => {
                setSimulation((current: any) => {
                  return { ...current, case_zero_infect_time: value };
                });
              }}
              value={currentSimulation.case_zero_infect_time}
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.case_zero_infect_time, timeText)}</InputAdornment>,
              }}
            />
          </InputItem>
        </>
      }
      <InputItem>
        <Tag id="qr-support-label">Quiz is taken via QR Code</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, qr_support: value === 'true' };
            });
          }}
          row
          aria-label="Quiz is taken via QR Code"
          name="position"
          value={currentSimulation.qr_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      <InputItem>
        <Tag id="leaderboard-support-label">Leaderboard feature is enabled</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, leaderboard_support: value === 'true' };
            });
          }}
          row
          aria-label="Leaderboard feature is enabled"
          name="position"
          value={currentSimulation.leaderboard_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      <InputItem>
        <Tag id="raffle-support-label">Raffle feature is enabled</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, raffle_support: value === 'true' };
            });
          }}
          row
          aria-label="Raffle feature is enabled"
          name="position"
          value={currentSimulation.raffle_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      <InputItem>
        <Tag id="food-support-label">Food plays a role in Simulation</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, food_support: value === 'true' };
            });
            setFoodOptions(!foodOptions)
          }}
          row
          aria-label="Food plays a role in Simulation"
          name="position"
          value={currentSimulation.food_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      {showFoodOptions &&
        <>
          <InputItem>
            <NumberInput
              title="How long food is effective"
              setWidth={200}
              min={0}
              required
              requiredString="Number required."
              setValue={(value: string) => {
                setSimulation((current: any) => {
                  return { ...current, food_interval: value };
                });
              }}
              value={currentSimulation.food_interval}
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.food_interval, timeText)}</InputAdornment>,
              }}
            />
          </InputItem>
        </>
      }
      <InputItem>
        <Tag id="score-support-label">Quiz score determines illness recovery</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, score_support: value === 'true' };
            });
            //setScoreOptions(!scoreOptions)
          }}
          row
          aria-label="Quiz score determines illness recovery"
          name="position"
          value={currentSimulation.score_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      <InputItem>
        <Tag id="debug-support-label">Debug features are enabled</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, debug_support: value === 'true' };
            });
          }}
          row
          aria-label="Debug features are enabled"
          name="position"
          value={currentSimulation.debug_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      <InputItem>
        <Tag id="sequence-support-label">Sequence Features are enabled</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, sequence_support: value === 'true' };
            });
            setSequenceOptions(!sequenceOptions)
          }}
          row
          aria-label="Sequence Features are enabled"
          name="position"
          value={currentSimulation.sequence_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      {showSequenceOptions &&
        <>
          <InputItem>
            <NumberInput
              required
              min={1}
              max={100}
              setWidth={60}
              requiredString="Number required."
              title='Percentage of Genome Coverage'
              value={currentSimulation.genome_coverage}
              setValue={(value: number) => {
                setSimulation((current: any) => {
                  return { ...current, genome_coverage: value };
                });
              }}
            />
          </InputItem>
        </>
      }
      <InputItem>
        <Tag id="location-label">Location Access</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, use_location: value === 'true' };
            });
          }}
          row
          aria-label="Location Access"
          name="position"
          value={currentSimulation.use_location}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      <InputItem>
        <Tag id="beacon-label">Beacon Access</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, use_beacons: value === 'true' };
            });
          }}
          row
          aria-label="Beacon Access"
          name="position"
          value={currentSimulation.use_beacons}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      <InputItem>
        <Tag id="group-label">Group Selection</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, group_support: value === 'true' };
            });
            setGroupOptions(!groupOptions)
          }}
          row
          aria-label="Group Selection"
          name="position"
          value={currentSimulation.group_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      {showGroupOptions &&
        <>
          <InputItem>
            <Tag id="random-group-label">Random Group</Tag>
            <RadioGroup
              onChange={(e) => {
                const { value } = e.target;
                setSimulation((current: any) => {
                  return { ...current, random_group: value === 'true' };
                });
              }}
              row
              aria-label="Random Group"
              name="position"
              value={currentSimulation.random_group}
            >
              <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </InputItem>
          <InputItem>
            <Tag>Add Group</Tag>
            <Group
              groups={currentSimulation.group_selection}
              setValue={(value: any[]) => {
                setSimulation((current: any) => {
                  return { ...current, group_selection: value };
                });
              }}
            />
          </InputItem>
        </>
      }
      <InputItem>
        <Tag id="group-label">Intervention Support</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, intervention_support: value === 'true' };
            });
            setInterventionOptions(!interventionOptions)
          }}
          row
          aria-label="Intervention Support"
          name="position"
          value={currentSimulation.intervention_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      {showInterventionOptions &&
        <>
          {interventionsLoaded ?
            <Interventions
              title='Intervention list'
              currentInterventions={currentSimulation.interventions}
              allInterventions={interventions}
              setValue={(value: any[]) => {
                setSimulation((current: any) => {
                  return {
                    ...current, interventions: value
                  };
                });
              }}
            />
            :
            <div>No Interventions found</div>
          }
        </>
      }
      {/* <InputItem>
        <Tag id="group-label">Quarantine Support</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, quarantine_support: value === 'true' };
            });
            setQuarantineOptions(!quarantineOptions)
          }}
          row
          aria-label="Quarantine Support"
          name="position"
          value={currentSimulation.quarantine_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      {showQuarantineOptions &&
        <>
          <InputItem>
            <NumberInput
              title="Quarantine Interval"
              required
              requiredString="Quarantine interval required"
              setWidth={200}
              setValue={(value: string) => {
                setSimulation((current: any) => {
                  return { ...current, quarantine_interval: value };
                });
              }}
              value={currentSimulation.quarantine_interval}
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.quarantine_interval, timeText)}</InputAdornment>,
              }}
            />
          </InputItem>
          <InputItem>
            <NumberInput
              title="Quarantine Wait Time"
              required
              requiredString="Quarantine wait time required"
              setWidth={200}
              setValue={(value: string) => {
                setSimulation((current: any) => {
                  return { ...current, quarantine_wait_time: value };
                });
              }}
              value={currentSimulation.quarantine_wait_time}
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.quarantine_wait_time, timeText)}</InputAdornment>,
              }}
            />
          </InputItem>
          <InputItem>
            <NumberInput
              required
              min={1}
              max={100}
              setWidth={60}
              requiredString="Number required."
              title='Points to receive for no quarantine'
              value={currentSimulation.points_no_quarantine}
              setValue={(value: number) => {
                setSimulation((current: any) => {
                  return { ...current, points_no_quarantine: value };
                });
              }}
            />
          </InputItem>
          <InputItem>
            <NumberInput
              required
              min={1}
              max={100}
              setWidth={60}
              requiredString="Number required."
              title='Points to receive for quarantine'
              value={currentSimulation.points_quarantine}
              setValue={(value: number) => {
                setSimulation((current: any) => {
                  return { ...current, points_quarantine: value };
                });
              }}
            />
          </InputItem>
          <InputItem>
            <NumberInput
              required
              min={0}
              max={100}
              setWidth={60}
              requiredString="Number required."
              title='Points to receive for quarantine while sick'
              value={currentSimulation.points_symptomatic}
              setValue={(value: number) => {
                setSimulation((current: any) => {
                  return { ...current, points_symptomatic: value };
                });
              }}
            />
          </InputItem>
        </> */}
      <InputItem>
        <Tag id="group-label">Shop Support</Tag>
        <RadioGroup
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              return { ...current, shop_support: value === 'true' };
            });
            setShopOptions(!shopOptions)
          }}
          row
          aria-label="Shop Support"
          name="position"
          value={currentSimulation.shop_support}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
        </RadioGroup>
      </InputItem>
      {showShopOptions &&
        <>
          <InputItem>
            <Tag>Add Shop Item with Item Price</Tag>
            <ShopItem
              shopItems={currentSimulation.shop_items}
              setValue={(value: any[]) => {
                setSimulation((current: any) => {
                  return { ...current, shop_items: value };
                });
              }}
            />
          </InputItem>
        </>
      }
      <InputItem>
        <NumberInput
          required
          min={0}
          max={100}
          setWidth={60}
          requiredString="Number required."
          title='Minimum initial score'
          value={currentSimulation.init_score_min}
          setValue={(value: number) => {
            setSimulation((current: any) => {
              return { ...current, init_score_min: value };
            });
          }}
        />
      </InputItem>
      <InputItem>
        <NumberInput
          required
          min={0}
          max={100}
          setWidth={60}
          requiredString="Number required."
          title='Maximum initial score'
          value={currentSimulation.init_score_max}
          setValue={(value: number) => {
            setSimulation((current: any) => {
              return { ...current, init_score_max: value };
            });
          }}
        />
      </InputItem>
      <InputItem>
        <NumberInput
          required
          min={0}
          max={100}
          setWidth={60}
          requiredString="Number required."
          title='Points to receive for each correct answer'
          value={currentSimulation.points_answer}
          setValue={(value: number) => {
            setSimulation((current: any) => {
              return { ...current, points_answer: value };
            });
          }}
        />
      </InputItem>
      {currentSimulation.rewards &&
        <>
          <InputItem>
            <NumberInput
              title='How long a PPE protects for'
              setWidth={200}
              required
              requiredString="Duration required."
              value={currentSimulation.rewards.ppe_period}
              setValue={(value: number) => {
                setSimulation((current: any) => {
                  return {
                    ...current,
                    rewards: {
                      ...current.rewards,
                      ppe_period: value
                    }
                  };
                });
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.rewards.ppe_period, timeText)}</InputAdornment>,
              }}
            />
          </InputItem>
          <InputItem>
            <NumberInput
              title='How long a mask protects for'
              setWidth={200}
              required
              requiredString="Duration required."
              value={currentSimulation.rewards.mask_period}
              setValue={(value: number) => {
                setSimulation((current: any) => {
                  return {
                    ...current,
                    rewards: {
                      ...current.rewards,
                      mask_period: value
                    }
                  };
                });
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{isPlural(currentSimulation.rewards.mask_period, timeText)}</InputAdornment>,
              }}
            />
          </InputItem>
          <InputItem>
            <NumberInput
              required
              min={1}
              setWidth={60}
              requiredString="# of questions required."
              title='Minimum # of quiz questions received to gain protection'
              value={currentSimulation.rewards.min_questions}
              setValue={(value: number) => {
                setSimulation((current: any) => {
                  return {
                    ...current,
                    rewards: {
                      ...current.rewards,
                      min_questions: value
                    }
                  };
                });
              }}
            />
          </InputItem>
          <InputItem>
            <NumberInput
              required
              min={1}
              max={100}
              setWidth={60}
              requiredString="Min. score required."
              title='Minimum quiz score to receive a mask'
              value={currentSimulation.rewards.min_score_mask}
              setValue={(value: number) => {
                setSimulation((current: any) => {
                  return {
                    ...current,
                    rewards: {
                      ...current.rewards,
                      min_score_mask: value
                    }
                  };
                });
              }}
            />
          </InputItem>
          <InputItem>
            <NumberInput
              required
              min={1}
              max={100}
              setWidth={60}
              requiredString="Min. score required."
              title='Minimum quiz score to receive a PPE'
              value={currentSimulation.rewards.min_score_ppe}
              setValue={(value: number) => {
                setSimulation((current: any) => {
                  return {
                    ...current,
                    rewards: {
                      ...current.rewards,
                      min_score_ppe: value
                    }
                  };
                });
              }}
            />
          </InputItem>
        </>
      }
    </div>
  );
};

export const Line = styled.span`
  margin-left: 12px;
  margin-right: 12px;
  background-color: #000;
  height: 3px;
  width: 24px;
`;

export const TextSpacer = styled.span`
  margin-left: 12px;
  margin-right: 12px;
`;
