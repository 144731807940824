import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import { globalReducer } from './root.reducer'
import { globalSagas } from './root.sagas'

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const middlewares = [
  routerMiddleware(history),
  sagaMiddleware,
]

const storeMiddleware = (process.env.NODE_ENV === 'development')
  ? composeWithDevTools(applyMiddleware(...middlewares))
  : applyMiddleware(...middlewares)

const store = createStore(
  globalReducer(history),
  storeMiddleware,
)

sagaMiddleware.run(globalSagas)

export { store }
