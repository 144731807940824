import styled from 'styled-components'
import { Flex } from '../styles/global'

import { UnitType } from '../models'


// any ui helper methods
export const renderTables = () => { }

export const removeQuestion = (arr: any, question: any) => {
    const result = arr.filter((item: any) => item.id !== question.id)
    console.log("Result: ", result, "Question: ", question)
    return result
}

// returns a new string with the query params removed
export const removeQueryParams = (str: string) => {
    let parsed = "";
    for (let i = 0; i < str.length; i++) {
        if (str[i] === "?") {
            break
        }
        parsed += str[i]
    }
    return parsed
}

export const adjustRequestPrefix = (str: string) => {
    if (!str) {
        return ""
    }
    if (str.includes("http://")) {
        return "https://" + str.split('').slice(7).join('')
    }
    if (str.includes("https://")) {
        return str
    }
    return "https://" + str
}

export const appendRequestPrefix = (str: string) => {
    return "https://" + str
}

export const ViewContainer = styled.div`
    position: absolute;
    width: calc(100% - 245px);
    min-height: 100%;
    z-index: 2;
    top: 0;
    background: #333333;
    /* padding-left: 28px; */
`

export const Container = styled.div`
    /* background: #FF0; */
`

export const Wrapper = styled(Flex)`
    background: #FAFAFA;
    padding: 28px 0;
    margin-bottom: 16px;

    padding-left: 16px;

    max-width: 500px;

    > ${Flex} > p {
        padding: 8px 0;
        font-weight: 600;
    }

    &:first-child {
        margin-top: 40px;
    }
`

export const makeId = (name: string): string => name.replace(/\s+/g, '-').toLowerCase();


export const pickTimeUnit = (unitType: UnitType) => {
    switch (unitType) {
        case UnitType.SECOND:
            return 'second'
        case UnitType.MINUTE:
            return 'minute'
        case UnitType.HOUR:
            return 'hour'
        case UnitType.DAY:
            return 'day'
        default:
            return ''
    }
}

export const isPlural = (number: number, singular: string, plural?: string): string => number !== 1 ? plural ? plural : `${singular}s` : singular
