import React from "react";
import styled from "styled-components";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button'

import { Tag } from '../styles/global'


import { Input } from './Input'

import { Intervention, APIIntervention  } from "../models"

interface Props {
  title: string;
  currentInterventions: Intervention[];
  allInterventions: APIIntervention[]
  setValue: (value: any) => void;
}

const createIntervention = (interventionName : string, interventionParameters : string) => 
  ({name: interventionName,  parameters: interventionParameters})
export const Interventions = ({ title, currentInterventions, allInterventions, setValue }: Props) => {
  const remainingInterventions = allInterventions.filter(intervention =>
    currentInterventions.every(currentIntervention => currentIntervention.name !== intervention.name));

  return (
    <InterventionsWrapper>
      <Tag>{title}</Tag>
      {currentInterventions.map((intervention: any, index: number) => (
        <InterventionWrapper key={intervention.name + index}>
          <StyledSelect
            labelId="intervention-label"
            id="intervention-select"
            variant="outlined"
            value={intervention}
            onChange={(e) => {
              const { value }: { value: any } = e.target;
              console.log(intervention)
              let newInterventions = currentInterventions
              newInterventions[index].name = value.name
              newInterventions[index].parameters = value.parameters
              setValue(newInterventions);
            }}
          >
            <MenuItem key={intervention.id} value={intervention}>{intervention.name}</MenuItem>
          {remainingInterventions.map((value) => (
            <MenuItem key={value.name} value={value as any}>{value.name}</MenuItem>
          ))}
          </StyledSelect>
          <Input
            //title="Parameters"
            required
            requiredString="Parameters required."
            setValue={(value: string) => {
              let newInterventions = currentInterventions
              newInterventions[index].parameters = value
              setValue(newInterventions);
            }}
            value={intervention.parameters}
            as="textarea" // Use textarea for larger input
              style={{
                //height: "100px",       // Makes the box taller
                width: "50%",         // Makes the box wider
                resize: "vertical",    // Allows manual resizing vertically
                padding: "10px",       // Adds some padding for readability
                textAlign: "start",    // Align text to the left
                verticalAlign: "top",  // Ensure text starts from the top
              }}
          />
          {allInterventions.length >= 2 &&
            <button
              onClick={() => {
                let newInterventions = currentInterventions
                newInterventions.splice(index,1)
                setValue(newInterventions);
              }}
              >
            X
            </button>
          }
        </InterventionWrapper>
      ))}
      <StyledButton
        size="large"
        variant="contained"
        disabled={remainingInterventions.length === 0}
        disableElevation
        onClick={() => {
          let newInterventions = currentInterventions
          let newIntervention = 
            createIntervention(remainingInterventions[0].name, remainingInterventions[0].parameters);
          newInterventions.push(newIntervention)
          setValue(newInterventions);
        }}
      >
        Add Intervention
      </StyledButton>
    </InterventionsWrapper>
  );
};


const InterventionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledButton = styled(Button)`
  max-width: 280px;
  button {
    background: none;
  }
`

const StyledSelect = styled(Select)`
  width: 180px;
`
const InterventionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 9px 0px;
  margin: 0 -12px;
  ${StyledButton}, ${StyledSelect}, button  {
    margin: 0 12px !important;
  }
`
