import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ViewContainer } from '../utils/ui.utils'
import { Container } from '../styles/global'
import { submitSymptom } from '../utils/api.utils'
import Button from '@material-ui/core/Button'

import Fab from '@material-ui/core/Fab';

import { Input } from '../components/Input'

import { InputItem } from '../styles/global'

interface Props {
  symptom: any
  simulation?: any | undefined
  shouldDisplay: any
  fetchSymptoms: any
}

export function SymptomEditor({ symptom, simulation, shouldDisplay, fetchSymptoms }: Props) {
  const [currentSymptom, setSymptom] = useState(symptom);
  // const [isValid, setIsValid] = useState<boolean>(false);

  const isValid = currentSymptom.name !== ''
  // && currentSymptom.label !== ''
  // && currentSymptom.description !== ''
  // && currentSymptom.url !== ''

  if (currentSymptom.name === undefined) currentSymptom.name = '';
  if (currentSymptom.label === undefined) currentSymptom.label = '';
  if (currentSymptom.description === undefined) currentSymptom.description = '';
  if (currentSymptom.url === undefined) currentSymptom.url = '';

  useEffect(() => {
    setSymptom((targetSymptom: any) => ({ ...targetSymptom }))
  }, []);

  function saveSymptom() {
    // send Symptom to network
    submitSymptom(currentSymptom)
      .then((response: any) => {
        fetchSymptoms()
        shouldDisplay(false)
        return response
      }).catch((err: any) => console.error(err))
  }

  return (
    <>
      <ContainerWrapper>
        <Container>
          <Container>
            <Button onClick={() => shouldDisplay(false)}>{`< ${currentSymptom.name || 'Untitled Symptom'}`}</Button>
          </Container>
          <InputItem>
            <Input
              title="Symptom Name"
              required
              requiredString="Name required."
              value={currentSymptom.name}
              setValue={(value: string) => {
                setSymptom((current: any) => {
                  return { ...current, name: value };
                });
              }}
            />
          </InputItem>
          <InputItem>
            <Input
              title="Label"
              requiredString="Label required."
              value={currentSymptom.label}
              setValue={(value: string) => {
                setSymptom((current: any) => {
                  return { ...current, label: value };
                });
              }}
            />
          </InputItem>
          <InputItem>
            <Input
              title="Description"
              requiredString="Description required."
              value={currentSymptom.description}
              setValue={(value: string) => {
                setSymptom((current: any) => {
                  return { ...current, description: value };
                });
              }}
            />
          </InputItem>
          <InputItem>
            <Input
              title="URL"
              requiredString="URL required."
              value={currentSymptom.url}
              setValue={(value: string) => {
                setSymptom((current: any) => {
                  return { ...current, url: value };
                });
              }}
            />
          </InputItem>
          <FabWrapper
            variant="extended"
            color="primary"
            disabled={!isValid}
            onClick={() => saveSymptom()}
          >
            Save Symptom
          </FabWrapper>
        </Container>
      </ContainerWrapper>
    </>
  );
}

const ContainerWrapper = styled(ViewContainer)`
  background: #FFF;
`;

const FabWrapper = styled(Fab)`
  position: fixed !important;
  right: 40px;
  bottom: 40px;
`;
