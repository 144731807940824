import React, { useState } from "react";
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar';

import { Alert } from '../components/Alert'
import { AlertType } from '../models'
import { Input } from '../components/Input'
import { InputItem } from '../styles/global'
import { pushNotification } from '../utils/api.utils'

interface Props {
  code: string;
}

export const URLNotification = ({ code }: Props) => {
	const [notifTitle, setNotifTitle]  = useState<any>("Title");
	const [notifURL, setNotifURL]  = useState<any>("https://operationoutbreak.org");
	const [notifDesc, setNotifDesc]  = useState<any>("");
	const [notifArgs, setNotifArgs]  = useState<any>("");
	const [alertMessage, setAlertMessage] = useState<string>('');
	const [alertVisible, setAlertVisible] = useState<boolean>(false);
	const [alertSeverity, setAlertSeverity] = useState<AlertType>(AlertType.SUCCESS);

	const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
	    if (reason === 'clickaway') {
	      return;
	    }

	    setAlertVisible(false);
	    setAlertMessage("")
	    setAlertSeverity(AlertType.SUCCESS)
	 };

	return (
	 	<div>
	 		<Snackbar open={alertVisible} autoHideDuration={6000} onClose={handleAlertClose}>
		        <Alert onClose={handleAlertClose} severity={alertSeverity}>
		          {alertMessage}
		        </Alert>
	      	</Snackbar>
			<InputItem>
		        <Input
		          title="Enter Title"
		          placeholder="Title"
		          required
		          requiredString="Title required."
		          value={notifTitle}
		          setValue={(value: string) => {
		            setNotifTitle(value);
		          }}
		        />
			</InputItem>
			<InputItem>
		        <Input
		          title="Enter Description"
		          placeholder="Description"
		          value={notifDesc}
		          setValue={(value: string) => {
		            setNotifDesc(value);
		          }}
		        />
			</InputItem>
			<InputItem>
		        <Input
		          title="Enter URL"
		          placeholder="URL"
		          required
		          requiredString="URL required."
		          value={notifURL}
		          setValue={(value: string) => {
		            setNotifURL(value);
		          }}
		        />
			</InputItem>
			<InputItem>
		        <Input
		          title="Enter Argument"
		          placeholder="Argument"
		          value={notifArgs}
		          setValue={(value: string) => {
		            setNotifArgs(value);
		          }}
		        />
			</InputItem>
			<Button
              size="large"
              variant="contained"
              disableElevation
              onClick={() => {
                pushNotification(code, notifTitle, notifDesc, notifURL, notifArgs)
                  .then((response: any) => {
                    setAlertMessage("Notification Pushed")
                    setAlertVisible(true)
                    return response
                  })
                  .catch((err: any) => console.error(err))
              }}
            >
              Push Notification
            </Button>
	  	</div>
	);
};
