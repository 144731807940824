import React, { useState, useEffect } from "react";

import sortBy from "lodash/sortBy";
import styled from "styled-components";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Pathogen, UnitType } from "../models";
import { Input } from '../components/Input'
import { InputItem, Tag } from '../styles/global'

import { getPathogens } from "../utils/api.utils";

import { calculatePathogen } from "../utils/equation.utils";

import { checkAdmin } from '../utils/auth.utils'


import { PathogenForm } from './PathogenForm';

interface Props {
  currentSimulation: any;
  setSimulation: any;
  setCustomPathogen: any;
  customPathogen: any;
  timeStep?: UnitType;
  currentUnitType?: UnitType;
}

export const PathogenSettings = ({ currentSimulation, setSimulation, setCustomPathogen, customPathogen, timeStep, currentUnitType }: Props) => {
  const [pathogens, setPathogens] = useState<Pathogen[]>([]);
  const pathogensLoaded = pathogens && pathogens.length >= 1;
  const admin =  checkAdmin();
  const valid = currentSimulation.pathogen_id;

  const fetchPathogens = () => {
    getPathogens()
    .then((result: any) => {
      const payload = result as any;
      setPathogens(sortBy(payload, "created_at"));
    })
    .catch((err: any) => setPathogens([]));
  };


  useEffect(() => {
    fetchPathogens()
  }, []);

  return (
    <div>
      <InputItem>
        <Tag id="pathogen-label">Pathogen</Tag>
        <StyledSelect
          labelId="pathogen-label"
          id="pathogen-select"
          variant="outlined"
          label="Pathogen"
          error={!valid}
          disabled={!pathogensLoaded || currentSimulation.is_modified}
          value={currentSimulation.pathogen_id}
          onChange={(e) => {
            const { value } = e.target;
            setSimulation((current: any) => {
              if(value !== 0){
                let newPathogen = JSON.parse(JSON.stringify(pathogens.filter(pathogen => pathogen.id === value)[0]));
                //calculate simulation dependent variables
                setCustomPathogen(calculatePathogen(currentSimulation, newPathogen, timeStep))
                return {
                  ...current,
                  pathogen_id: value,
                };
              }
            });
          }}
        >
          {pathogensLoaded &&
            pathogens.map((pathogen: any) => (
              <MenuItem key={pathogen.id} value={pathogen.id}>{pathogen.name}</MenuItem>
            ))
          }
        </StyledSelect>
        {valid ? "" : <Error>You must select a Pathogen</Error>}
      </InputItem>
      {admin &&
        <InputItem>
          <Tag id="beacon-label">Override Settings</Tag>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!pathogensLoaded || currentSimulation.pathogen_id === 0}
                checked={currentSimulation.is_modified}
                onChange={(e) => {
                  setSimulation((current: any) => {
                    let newPathogen = JSON.parse(JSON.stringify(pathogens.filter(pathogen => pathogen.id === currentSimulation.pathogen_id)[0]));
                    if(!currentSimulation.is_modified){
                      setCustomPathogen(calculatePathogen(currentSimulation, newPathogen, timeStep))
                    }
                    return { ...current, is_modified: !currentSimulation.is_modified, pathogen_name: `${newPathogen.name} (Modified)` };
                  });
                }}
                color="primary"
              />
            }
            label="Override"
          />
        </InputItem>
      }
      {currentSimulation.is_modified  &&
        <>
        <InputItem>
          <Input
            title="Pathogen Name"
            required
            requiredString="Name required."
            value={currentSimulation.pathogen_name}
            setValue={(value: string) => {
              setSimulation((current: any) => {
                return { ...current, pathogen_name: value };
              });
            }}
          />
        </InputItem>
        <PathogenForm pathogen={customPathogen} simulation={currentSimulation} setPathogen={setCustomPathogen} timeStep={timeStep} currentUnitType={currentUnitType}/>
        </>
      }
    </div>
  );
};


const Error = styled.div`
  display: block;
  font-weight: bold;
  margin-left: 12px;
  color: red;
`
const StyledSelect = styled(Select)`
  min-width: 100px;
`
