import React from "react";

import { StyledNumberInput, Tag } from "../styles/global";

import { makeId } from "../utils/ui.utils";


interface Props {
  title?: string;
  value: string;
  setValue: (value: any) => void;
  isValid?: (bool: boolean) => {};
  min?: number;
  max?: number;
  required?: boolean;
  requiredString?: string;
  // allowing spread props
  [x:string]: any;
  setWidth?: number;
}

export const NumberInput = ({ title, value, setValue, isValid, min = 0, max, required, requiredString, setWidth = 110, ...props }: Props) => {
  const validate = (value: any): boolean => {
    const valid = value !== undefined && value !== null && value !== ''
    if (isValid) {
      isValid(valid);
    }
    //error prop expects the opposite
    return !valid;
  }

  const pickValue = (value: string): number | undefined => {
    const num = parseFloat(value);
    if(value === undefined || value === '' || num < min){
      return min
    }
    if(max && num > max){
      return max
    }
    return num
  }

  const helperText = (value: string): string | undefined => {
    if(required && value === ''){
      return requiredString || `${title} required.`
    }
    const num = parseFloat(value);
    if(min && num < min){
      return `Minimum is ${min}`
    }
    if(max && num > max){
      return `Maximum is ${max}`
    }
    return undefined
  }
  const labelId = `${makeId(title || 'o2')}-label`
  return (
    <>
      {title && <Tag id={labelId}>{title}{required && ` *`}</Tag>}
      <StyledNumberInput
        setWidth={setWidth}
        id={`${makeId(title || 'o2')}-input`}
        type="number"
        aria-describedby={labelId}
        required={required}
        error={validate(value)}
        helperText={helperText(value)}
        variant="outlined"
        onChange={(e: { target: { value: any; }; }) => {
          const number = parseFloat(e.target.value)
          setValue(number || e.target.value)
        }}
        onBlur={(e: any) => {
          setValue(pickValue(value))
        }}
        value={value}
        {...props}
      />
    </>
  );
};
